<template>
    <section class="feature-2 position-relative">
        <div id="mono-sculpting-trigger" class="position-absolute"></div>
        <div class="feature-2-wrapper container py-5">
            <div class="position-absolute pattern w-100 overflow-hidden">
                <svg id="pattern-4"
                     class="h-100"
                     v-bind:class="{ 'done': isPatternVivusDone }"
                     version="1.2" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 92.1721802 282.0259399" xml:space="preserve">
                    <path fill="transparent" stroke="#B7C4D5" d="M71.9460678-0.0000114
                        c0.6210709,6.1856079-0.4966965,12.4911804-2.646637,18.3487549c-2.1924744,5.8678894-5.521553,11.2775574-9.4626236,16.1300964
                        c-3.9123459,4.8859253-8.5255356,9.1834412-13.2068329,13.2341309c-4.6371765,4.0513-9.2940521,8.1670532-12.3011627,13.3692017
                        c-1.4958344,2.5861511-2.5988312,5.4094887-3.0327759,8.3255348c-0.4378662,2.9126587-0.1585999,5.898407,1.0059662,8.5985718
                        c1.0867233,2.73349,2.9492111,5.1455688,4.9739914,7.4083252c2.0544281,2.2549438,4.2987823,4.3886719,6.4248581,6.6885986
                        c2.1353073,2.2736206,4.1384964,4.7933655,5.5691757,7.6377869c1.4525375,2.8246155,2.3471909,5.9041748,2.7664871,9.0221863
                        c0.8158493,6.2346191-0.4052963,12.7335815-3.6355057,18.0405884c2.8248367-5.515625,3.7742996-11.8939514,2.6662292-17.8930969
                        c-0.5210495-2.9959106-1.4778671-5.9217529-2.9571228-8.559021c-1.4536209-2.6483154-3.381691-4.9955444-5.5412064-7.1739807
                        c-2.1389389-2.1919556-4.437767-4.2784729-6.5836716-6.5779114c-2.1236877-2.3047485-4.1684189-4.8400574-5.4314804-7.8654785
                        c-1.3389206-2.9862976-1.6598511-6.40802-1.1917648-9.6069641c0.4648743-3.2199707,1.646347-6.2725868,3.2369995-9.0466042
                        c3.2760162-5.5680847,8.1119385-9.7787781,12.7954865-13.7958069c4.7497406-3.9679565,9.2634277-8.1214905,13.245697-12.798645
                        c3.9613342-4.6687927,7.3870239-9.8294983,9.7530746-15.49646C70.805481,12.3519173,72.1146469,6.2014961,71.9460678-0.0000114z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M65.5342789,6.6676521
                        c-3.8274307,5.078125-6.1125946,10.9889221-9.6584702,16.3682251c-1.7512131,2.6936951-3.9026718,5.2181396-6.5321045,7.2192993
                        c-2.6339569,1.9784241-5.5694885,3.3723145-8.4077225,4.7547913c-5.6876373,2.654541-11.1925659,5.7071533-15.617218,10.0542297
                        c-2.1915436,2.1676025-4.0678787,4.6720581-5.4365005,7.4590149c-1.4107513,2.7677002-2.2218552,5.8506165-2.3211746,9.0220032
                        c-0.3566208-3.1651001,0.2668533-6.4153137,1.4251404-9.4199829c1.2048798-2.9977112,3.0007401-5.76651,5.2087402-8.1440125
                        c2.1872482-2.4000244,4.7623672-4.4043579,7.4480972-6.1757507c2.699585-1.7531128,5.5715027-3.2295227,8.4297256-4.5982971
                        c2.8599854-1.34729,5.6770401-2.6898499,8.1509171-4.4723511c1.2484894-0.8694458,2.3586807-1.9153442,3.4308701-3.0082397
                        c1.0429077-1.1239014,2.0187531-2.3240662,2.9429855-3.5757141C58.2347183,17.1165962,61.0994492,11.2616768,65.5342789,6.6676521z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M36.7796021,95.1601486
                        c0.6287766,1.5892944,1.1065598,3.2460938,1.4204483,4.9418945c0.2950974,1.697937,0.4492798,3.4232483,0.4837265,5.1566467
                        c-0.0008774,1.7327576-0.1461258,3.4638977-0.3818588,5.1842346c-0.2550507,1.717804-0.6524277,3.4107666-1.1157074,5.0850525
                        c-1.9072418,6.7166748-5.5710449,12.7611008-9.6624146,18.2694931c-4.1390762,5.5094604-8.7623062,10.5536499-13.1675272,15.7262573
                        c-4.3787575,5.1875-8.6012611,10.5287476-11.7960167,16.5834351c2.4867556-6.3890991,6.3748741-12.1811523,10.5845261-17.5673218
                        c4.1980181-5.4194946,8.7443895-10.5093994,12.7779894-15.9389954c4.0531998-5.3950729,7.6452408-11.1681137,9.7789764-17.5349655
                        C37.8082047,108.7367783,38.5993805,101.7876511,36.7796021,95.1601486z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M30.8921204,142.2670135
                        c1.9729309,2.6168213,3.0766296,5.8570557,3.3586426,9.1439209c0.2957306,3.2939453-0.1449661,6.6481323-1.1910248,9.8060303
                        c-2.1947861,6.3331299-6.6110535,11.5725708-11.3892517,16.0552368c-2.3976593,2.2733154-4.9783478,4.2963257-7.2900553,6.5048828
                        c-2.3226318,2.1937256-4.2303505,4.736145-5.7810059,7.5299072c-1.5654635,2.7789917-2.7545123,5.7816162-3.7435641,8.8310547
                        c-0.9757347,3.0554199-1.724308,6.1897583-2.1703529,9.3722534c-1.9785423,12.7399292-0.0372162,25.9067993,4.3157845,38.0706177
                        c2.1292109,6.1222534,4.8961215,12.0240021,8.0036736,17.7383575c3.0554895,5.7506714,6.5682383,11.2540894,10.1620035,16.706665
                        c-3.9641113-5.1911621-7.6509399-10.6020508-11.0153666-16.223938c-3.3018532-5.6547852-6.2556224-11.5402985-8.594696-17.6914215
                        c-2.3931236-6.128479-4.0278015-12.5705566-4.8930702-19.1150513c-0.8875732-6.5424194-0.8980293-13.2214966,0.0466499-19.7852783
                        c0.4912605-3.2797241,1.2935867-6.5128784,2.3320847-9.6633911c1.0528033-3.1450195,2.3071291-6.2318726,3.9744914-9.1325073
                        c1.6394229-2.8999634,3.7216792-5.6182251,6.1720691-7.8866577c2.4182358-2.2965088,5.0431452-4.2386475,7.4499292-6.3875122
                        c4.8126144-4.2630005,9.2037354-9.2261963,11.503334-15.2525635c1.1435547-2.9874878,1.7239456-6.2179565,1.5824661-9.4405518
                        C33.5773087,148.234787,32.6864166,145.0077362,30.8921204,142.2670135z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M63.2625122,96.2429733
                        c1.3199005,2.6094666,3.1483231,4.913208,5.2113724,6.9411316c2.0748444,2.0236511,4.4139175,3.7599487,6.8959732,5.2351379
                        c4.9645767,2.9291382,10.4946823,4.9900818,16.180748,5.6203613l0.6159821,0.0682678l0.0045929,0.6317749
                        c0.018158,2.4920044-0.2107544,4.9576416-0.6441193,7.4061584c-0.435379,2.4452209-1.1250916,4.8670959-2.1915894,7.1608505
                        c-1.0639038,2.2801514-2.5272827,4.4761047-4.5900726,6.1054077c-2.0433502,1.6499939-4.6570282,2.5549622-7.2431107,2.7072754
                        l0.6753616-1.5007324c2.409256,3.0056458,3.8370361,6.6904602,4.4221344,10.4260559
                        c0.6065674,3.7491455,0.373764,7.5724487-0.4028625,11.2419434c-1.5978088,7.3731079-5.2522202,13.9211426-8.7315979,20.3117065
                        c-3.534317,6.3791504-6.9208603,12.8352051-10.0298767,19.4368896c-1.5445251,3.303833-3.1027451,6.6046753-4.5701141,9.9480591
                        c-1.4697189,3.3421021-2.8861084,6.7091675-4.2805023,10.0888062c1.1458435-3.4720459,2.392662-6.9110107,3.7071915-10.3250122
                        c1.3168716-3.4127197,2.6639404-6.8180542,4.1438065-10.1663208c2.9367371-6.7040405,6.0853119-13.3301392,9.5237427-19.7949829
                        c3.4241943-6.425354,6.9114685-12.8872681,8.348587-19.8936157c0.7037048-3.4784546,0.8513794-7.0680542,0.3531952-10.5477905
                        c-0.5093384-3.4718628-1.7868347-6.8374634-3.9353714-9.5742493l-1.1030197-1.4049988l1.7784576-0.0959167
                        c2.2676544-0.1222839,4.463028-0.8621826,6.2374115-2.2623596c1.7884979-1.3824768,3.1338348-3.330719,4.136734-5.434967
                        c1.0007935-2.1159897,1.6817627-4.4034653,2.1763458-6.731041c0.477951-2.33078,0.7639313-4.7260437,0.8092346-7.1011658
                        l0.6203766,0.6998291c-2.95755-0.4037781-5.8358154-1.1940308-8.59729-2.2545471
                        c-2.7585297-1.0672607-5.4174042-2.3929138-7.8781509-4.0232849c-2.4639435-1.6201782-4.7626343-3.4956055-6.7735291-5.6436462
                        C66.1193466,101.3779831,64.4154968,98.9298935,63.2625122,96.2429733z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M46.8264999,205.8281097
                        c3.708786,4.0348511,6.7812119,8.6859741,9.2053833,13.6398315c2.4556274,4.9464722,4.2876282,10.1794434,5.7424698,15.4863281
                        c1.4194031,5.3208008,2.4652634,10.7229004,3.216423,16.1522827c0.7787933,5.42659,1.2469254,10.8816071,1.5663452,16.3471832
                        c-1.0904312-5.3666992-2.0748215-10.741272-3.1055069-16.0888214c-1.0588684-5.3421021-2.1764374-10.6618042-3.6068344-15.8890991
                        c-1.3948212-5.2354126-3.105835-10.3778076-5.2246475-15.3566284
                        C52.4776535,215.153183,49.9274979,210.344162,46.8264999,205.8281097z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M62.5434723,196.6738129
                        c7.4678268,10.9776001,12.7623901,23.4503784,15.9953232,36.3790894c1.5876007,6.4770508,2.6800385,13.0786743,3.1674805,19.7233276
                        c0.515976,6.6406403,0.411438,13.322525-0.3207397,19.9148712c-0.0448456-6.631958-0.4645996-13.2249146-1.2325897-19.7635651
                        c-0.796463-6.5349121-1.9561005-13.0178833-3.5542374-19.3937378
                        C73.4609451,220.7588348,68.6452866,208.443222,62.5434723,196.6738129z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M72.9999466,8.3640633
                        c1.0700912,1.8497925,1.3723679,4.0467834,1.5785828,6.142334c0.1027298,1.0577087,0.1756897,2.1203308,0.209877,3.1856995
                        c0.0294724,1.067627-0.0163651,2.1347351,0.0479736,3.137085c0.0539474,1.0122681,0.2762527,1.935791,0.6967468,2.7821045
                        c0.416748,0.8483582,1.0558777,1.5855408,1.7816544,2.237915c1.4755859,1.296875,3.3026886,2.2554932,5.1731262,3.1643677
                        l0.8544922,0.4152222l-0.4781189,0.8611755c-0.9740753,1.7545166-1.888916,3.6046753-3.0391846,5.3964233
                        c-0.1460419,0.2257996-0.2967834,0.4512939-0.462738,0.6787415c-0.1094513,0.1565857-0.0848999,0.1168213-0.10466,0.1609802
                        c-0.0150757,0.0256958-0.0231323,0.0658264-0.0325775,0.1143799c-0.0404663,0.2012329,0.032608,0.6010437,0.1875,0.9890137
                        c0.304245,0.7880554,0.863205,1.6344299,1.4160461,2.3450928l0.604599,0.7772217l-0.7420959,0.6267395
                        c-0.562149,0.4747314-1.162262,1.0752563-1.5157623,1.6253357c-0.1876984,0.2568054-0.232666,0.5697021-0.2225189,0.4525146
                        c0.076416,0.3250122,0.2080536,0.7196655,0.343399,1.1232605c0.2621002,0.7990417,0.609314,1.8192444,0.4174347,2.9567261
                        c-0.1890259,1.1099854-0.7509918,1.9384766-1.1975861,2.6463013c-0.4635468,0.7194824-0.833519,1.3322449-0.9664001,1.9826965
                        c-0.1509552,0.6844482-0.1260071,1.5223999-0.237587,2.460144c-0.0968323,0.9542542-0.4926605,1.9702148-1.0930252,2.7451172
                        c-0.5956039,0.7959595-1.3766327,1.3959351-2.2083664,1.8383789c-1.6551437,0.8904724-3.6214523,1.1470947-5.4005661,0.8201904
                        c-1.7847824-0.3135986-3.4169083-1.0263367-4.9299164-1.8461609c-1.5120087-0.8356323-2.936348-1.7515259-4.391304-2.6383667
                        c-1.4537125-0.8842163-2.8946762-1.7901306-4.3269348-2.7096558c3.0837631,1.4330139,6.0858688,3.0801697,9.1493988,4.477356
                        c1.5227585,0.6898499,3.0901108,1.2565918,4.6794815,1.4281921c1.5775299,0.1784363,3.1492157-0.1008911,4.4639816-0.8864441
                        c1.3187714-0.7664795,2.2174988-1.9955444,2.3229065-3.424408c0.0780563-0.7505188,0.0157242-1.6269836,0.1909332-2.6159973
                        c0.1851654-1.0335999,0.7137985-1.9088135,1.1443024-2.6234436c0.438652-0.7275391,0.8157501-1.3760071,0.8976822-1.9620361
                        c0.1005249-0.5623779-0.0855942-1.2310486-0.3630524-2.0247803c-0.1364746-0.4010315-0.2849045-0.8101807-0.408432-1.3112793
                        c-0.1017151-0.4738159-0.0138245-0.8671265,0.0876236-1.1642761c0.1055756-0.3043213,0.2390137-0.5496216,0.3776779-0.7739868
                        c0.5724945-0.8788147,1.1981659-1.4853516,1.9202271-2.1166992l-0.1374817,1.4036865
                        c-0.7073975-0.899231-1.2819519-1.789856-1.7236176-2.9135742c-0.1988068-0.5638733-0.397438-1.1949768-0.2728119-2.0144958
                        c0.0368729-0.2025146,0.0936813-0.4133606,0.1933212-0.6169128c0.0789032-0.1932068,0.2669296-0.4352722,0.3031082-0.4880676
                        c0.138031-0.1848755,0.2744446-0.3838806,0.4085693-0.586853c1.0736389-1.6533508,2.0146942-3.4651794,3.0965271-5.2547607
                        l0.376297,1.276123c-1.8744965-0.9971619-3.826004-2.0385437-5.4425125-3.6340332
                        c-0.7987213-0.7941284-1.5246887-1.7173462-1.9876328-2.7915649c-0.4790878-1.0669861-0.6550369-2.2573853-0.6314545-3.3525085
                        c0.0744781-2.1936035,0.2947006-4.2044067,0.2732315-6.3235474C73.8757324,12.4503975,73.7840271,10.3394661,72.9999466,8.3640633z"></path>
                    <path fill="transparent" stroke="#B7C4D5" d="M63.8203125,57.1980782
                        c-1.6778641,8.2691994-4.5457535,16.2445412-8.4608154,23.6097755c3.3143692-7.6543884,5.497818-15.7446289,6.4865723-23.9297218
                        L63.8203125,57.1980782z"></path>
                </svg>
            </div>

            <h1 class="position-relative text-center font-weight-bold mb-5"
                data-aos="fade"
                data-aos-duration="1000">
                7大難減鬼祟位
            </h1>

            <div class="icons row">
                <div class="col-sm-6 col-lg-4 d-flex flex-column align-items-center mb-4">
                    <svg class="icon side-breast mb-2" version="1.2" baseProfile="tiny" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 269.2913513 269.2913513" xml:space="preserve">
                        <g>
                            <circle fill="#FFFFFF" stroke="#576C80" stroke-width="6" stroke-miterlimit="10" cx="134.6456757" cy="134.6456757" r="127.1374512"></circle>
                            <g>
                                <path fill="#576C80" d="M59.8752441,136.7820282c3.2194824,6.4649658,7.0809326,12.6047974,11.3468628,18.4320679
                                    c2.1209106,2.9234009,4.3563843,5.7612305,6.6427002,8.5491333c2.309021,2.7872925,4.5448608,5.5565796,6.996521,8.2769775
                                    c2.4490356,2.6913452,5.0640259,5.2139893,7.7886963,7.6132202c2.7247314,2.399292,5.5606079,4.6765747,8.543396,6.7727051
                                    c2.9891968,2.0957031,6.0689087,4.0491333,9.5014038,5.5809937c3.4494019,1.4666138,7.0409546,2.3370361,10.6314087,3.0081787
                                    c3.5960693,0.6560059,7.2124023,1.0891113,10.8286133,1.3859253c3.6209717,0.2896118,7.2178345,0.5055542,10.8825684,0.5380249
                                    c3.6551514,0.0159912,7.3731079-0.1924438,11.0405884-1.0402832c3.6618042-0.8018799,7.2685547-2.3743896,10.2901611-4.7904053
                                    c3.0165405-2.4332886,5.2617188-5.6918945,6.7660522-9.1477661c1.5170898-3.4680786,2.3758545-7.1307983,2.8843384-10.7857056
                                    c0.5307007-3.6626587,0.59552-7.3370361,0.6060791-10.9672241c0.0144653-1.8203125-0.0827637-3.6379395-0.1193237-5.4568481
                                    c-0.1142578-1.8143311-0.2095947-3.6310425-0.3625488-5.4415894c-0.6329346-7.2421875-1.803894-14.4193726-3.3690796-21.4921265
                                    c-1.5722046-7.0734863-3.5384521-14.0405884-5.7219849-20.9233398c-2.171875-6.8879395-4.5758057-13.6888428-7.0948486-20.4378052
                                    c-5.0175171-13.5077515-10.418396-26.8690834-16.2061768-40.0564613
                                    c-5.8076172-12.9677124-11.8937378-25.8088989-18.499939-38.3775635
                                    c-0.0085449,0.0007324-0.0170288,0.0015869-0.0255737,0.0023193
                                    c6.0076904,12.8663931,11.8186035,25.8157959,17.1591797,38.9586792
                                    c5.510437,13.2746582,10.6459351,26.7006264,15.3842163,40.2615395
                                    c4.6888428,13.5710449,9.2686157,27.1938477,12.2162476,41.1824951
                                    c1.4986572,6.9811401,2.6394653,14.0332031,3.2313232,21.1274414c0.1438599,1.7731323,0.2306519,3.5498657,0.3372803,5.3247681
                                    c0.0288086,1.7767334,0.1192017,3.553833,0.0987549,5.329834c-0.038147,1.7727051-0.0132446,3.5603027-0.1174316,5.3161011
                                    c-0.0948486,1.7598877-0.2481689,3.5114136-0.4805298,5.2450562c-0.4685669,3.4624023-1.2681885,6.8720703-2.6287231,10.0067139
                                    c-1.3522949,3.1292114-3.3101807,5.9609985-5.9009399,8.0667725c-2.6017456,2.0861816-5.7419434,3.4989624-9.097229,4.2450562
                                    c-3.348938,0.7783813-6.8596802,1.0288086-10.3896484,1.0470581c-3.5288696,0.0065918-7.1201782-0.175293-10.6706543-0.4290771
                                    c-3.5542603-0.2598877-7.0932617-0.6536865-10.5795288-1.2578735c-3.4810181-0.6088257-6.9215088-1.4475708-10.1240234-2.7403564
                                    c-3.1680908-1.3436279-6.2440796-3.1815186-9.1768799-5.1575317c-2.9450073-1.9801025-5.7722778-4.1556396-8.4933472-6.4545898
                                    c-2.7214966-2.298645-5.3416748-4.7250977-7.7879639-7.3054199c-1.2276001-1.2773438-2.3891602-2.6300659-3.5753784-3.9894409
                                    c-1.1751709-1.357605-2.3562622-2.7099609-3.5098877-4.0827026c-2.3297119-2.7288208-4.5770264-5.5198975-6.7804565-8.3444824
                                    c-4.4006958-5.6525879-8.4041138-11.618103-11.7894897-17.9389648
                                    c-3.1379395-5.9187012-5.6887207-12.1594238-7.5543823-18.6202393l-0.0491943,0.043396
                                    C54.5442505,124.3846893,56.8726196,130.7466888,59.8752441,136.7820282z"></path>
                                <path fill="#576C80" d="M169.0961914,30.5461845c2.3446045,7.7943115,4.8614502,15.5436401,7.6444092,23.2058716
                                    c2.7993774,7.65448,5.7949219,15.2490883,9.1627808,22.6873817c3.3527832,7.4428101,6.9747314,14.7780151,11.0455322,21.8711548
                                    c2.0039673,3.5632324,4.1439209,7.0505981,6.3312378,10.5065308c2.2578735,3.4117432,4.5447998,6.8056641,7.0003662,10.0806885
                                    c4.8480835,6.5921631,10.197937,12.8236694,16.0233154,18.5662231c1.4347534,1.4387207,2.944397,2.8820801,4.5462646,4.1914673
                                    c1.6134033,1.298584,3.310791,2.4877319,5.0758667,3.5615845c3.5408936,2.1351318,7.3759155,3.7368164,11.3206177,4.8718262
                                    c3.949646,1.1282959,8.0251465,1.7670898,12.1097412,1.9225464c0.4122314,0.0158081,0.824707,0.0230103,1.2371826,0.0286255
                                    c0.0245972-0.1796265,0.0515137-0.3585205,0.0753174-0.5383911c-0.4271851-0.020874-0.8540649-0.0436401-1.2794189-0.0709839
                                    c-4.0289917-0.3064575-8.015686-1.0895386-11.8378906-2.3401489c-7.6512451-2.5036011-14.5941162-6.9071045-19.9051514-12.932373
                                    c-2.7700195-2.9400024-5.3833008-6.0220947-7.9475098-9.1375122c-2.5074463-3.1640625-4.9642944-6.3662109-7.2889404-9.6663208
                                    c-4.6691284-6.5847168-8.9415894-13.447937-12.895874-20.4923096
                                    c-3.9380493-7.0536499-7.6074219-14.2641602-10.9710693-21.6235962
                                    c-1.6893921-3.6757202-3.3179321-7.3814697-4.9127197-11.1031494c-1.5722046-3.7316322-3.0995483-7.4834023-4.5785522-11.2567177
                                    c-5.425354-13.8170776-10.28479-27.8765259-15.0990601-41.9696655c-0.1152344-0.0271606-0.2293091-0.057251-0.3446655-0.0841064
                                    C165.3289185,17.4264946,167.1802979,23.9927788,169.0961914,30.5461845z"></path>
                                <path fill="#576C80" d="M68.3519897,229.2629242c-1.1864014-12.53479-3.5464478-24.9890747-6.7738037-37.1434937
                                    c-3.1958618-12.151062-6.7280273-24.1107788-8.4219971-36.4493408
                                    c-0.9329834-6.1716309-1.6806641-12.3620605-2.1101685-18.5895386c-0.2229004-3.1130981-0.394165-6.2354126-0.4777222-9.3641968
                                    c-0.0623169-2.5455933-0.0971069-5.0957031-0.1287842-7.6480713l-0.1916504,0.1688843
                                    c-0.2246704,2.4855957-0.4122925,4.9748535-0.5252686,7.4703979c-0.1497192,3.1378784-0.2495728,6.2841797-0.227417,9.4335327
                                    c0.0363159,3.1483154,0.1539307,6.2990112,0.3614502,9.4433594c0.2248535,3.1427612,0.5227661,6.2793579,0.8742065,9.4067993
                                    c0.6829834,6.2610474,1.8486328,12.5186157,3.2763672,18.6452026c1.4584351,6.1293335,3.0928345,12.1928711,4.6834106,18.2385254
                                    c3.2630005,12.05896,5.7248535,24.2727051,7.3776245,36.6485596c0.5656128,4.3447266,1.0307007,8.704834,1.4066772,13.0760498
                                    c0.5892334,0.3673706,1.1797485,0.7327881,1.7753296,1.0907593
                                    C69.1169434,238.8755341,68.8198853,234.0621185,68.3519897,229.2629242z"></path>
                                <path fill="#576C80" d="M52.9464722,117.8586884l0.0491943-0.043396l3.3283081-2.9334717l4.7384033-4.1516113
                                    c1.5697632-1.3878784,3.1343994-2.7305298,4.8452759-3.8297119c1.6994629-1.1131592,3.5702515-1.9463501,5.5889893-2.2176514
                                    c1.9986572-0.2873535,4.149353-0.0124512,5.9472046,1.1135864c-1.6070557-1.3883057-3.842041-2.0148926-6.0123291-1.9378662
                                    c-2.1936646,0.0321045-4.3392334,0.7757568-6.2601929,1.7753296c-1.9264526,1.020874-3.6615601,2.3119507-5.3460083,3.5800171
                                    c-1.6787109,1.274353-3.3344116,2.5748901-4.9924316,3.8747559c-6.6261597,5.2059326-13.0593872,10.6536865-19.3637085,16.2451782
                                    c-3.12146,2.8300781-6.2418823,5.6615601-9.2996826,8.5602417c-3.039978,2.9175415-6.0826416,5.831665-9.0324707,8.8406982
                                    c-2.706542,2.7348633-5.3698721,5.5125122-7.9883413,8.3322144c0.0986328,0.6109619,0.1959229,1.2223511,0.3032227,1.8303223
                                    c3.1083984-2.8209839,6.2039185-5.6500854,9.3045645-8.4720459l9.3417358-8.4457397
                                    c3.1039429-2.8241577,6.2267456-5.6270752,9.3677368-8.4091187c3.1133423-2.8131714,6.2608032-5.5881958,9.4102783-8.3615723
                                    l3.3716431-2.9716797l0.1916504-0.1688843L52.9464722,117.8586884z"></path>
                                <path fill="#576C80" d="M167.7949829,252.3152313c-8.1262207-1.6619263-16.1452026-3.8577881-23.9473877-6.6662598
                                    c-3.9214478-1.3492432-7.7536011-2.9405518-11.5619507-4.5827637c-1.8746948-0.8851929-3.7857666-1.697998-5.6322632-2.6434937
                                    l-2.7930908-1.37323l-2.7510376-1.4558716c-14.6263428-7.8459473-28.085083-17.9778442-39.4984131-30.2304688
                                    c2.6572876,3.2286987,5.3883667,6.4203491,8.381897,9.3607788c1.4475708,1.5210571,3.0049438,2.9276733,4.5209961,4.3824463
                                    c1.5576782,1.4066162,3.1195068,2.8153687,4.7299805,4.1658936c6.4741821,5.3614502,13.3943481,10.2205811,20.7355957,14.3614502
                                    c14.6190186,8.4152222,30.7111206,14.1846924,47.2095337,17.6177979c1.6245728,0.3354492,3.2548828,0.6396484,4.8878784,0.927536
                                    c2.041748-0.6280975,4.0621338-1.3047943,6.0586548-2.0315399
                                    C174.6735229,253.6244965,171.2229614,253.0294647,167.7949829,252.3152313z"/>
                                <path fill="#576C80" d="M118.786377,11.7281923c1.0330811,1.5219727,1.9082642,3.1400757,2.6533813,4.8331289
                                    c0.7102661,1.7070313,1.2914429,3.4763794,1.7637329,5.2879639c1.7989502,7.3063354,1.5507813,15.1018677,1.5366821,22.8262329
                                    c0.649292-3.8191528,1.1394043-7.6621704,1.3762817-11.5568237c0.0913086-1.9478149,0.1187134-3.9056396,0.0384521-5.8722534
                                    c-0.0928345-1.9661255-0.3156128-3.9342041-0.6725464-5.8924561c-0.3773193-1.9539795-0.8892212-3.8970337-1.6127319-5.7770987
                                    c-0.7406616-1.8705444-1.6419067-3.6932373-2.7183838-5.4077759c-0.3999023-0.6188354-0.8305664-1.2155151-1.274231-1.8021851
                                    c-1.1356201,0.1314087-2.2666626,0.2771606-3.3929443,0.4382935
                                    C117.2950439,9.7457094,118.0720825,10.7138491,118.786377,11.7281923z"/>
                                <path fill="#576C80" d="M246.7289429,127.2057953c-1.2391357-3.5672607-2.6074219-7.0916138-4.215271-10.5115967
                                    c-1.5924072-3.4246826-3.359375-6.7728271-5.4641724-9.9385376c0.9956665,3.6674194,2.1585083,7.2299194,3.3220215,10.7844238
                                    l3.5285034,10.6231079l3.5880127,10.5996704c1.2125244,3.5354614,2.4474487,7.0722046,3.8214722,10.6026001l0.1470337,0.3778076
                                    l0.133667-0.333374l0.3597412-0.8972778l-0.376709,0.4476318c-0.3218384-3.6659546-0.9372559-7.2871704-1.7489624-10.8605957
                                    C248.9748535,134.4199677,247.9682007,130.7738495,246.7289429,127.2057953z"/>
                            </g>
                        </g>
                        <g class="crosshair">
                            <g>
                                <path fill="#EE3E3E" d="M154.0867462,140.5321198c-0.3271484,10.5160675-8.5358582,19.311142-19.311142,19.311142
                                    c-10.5229492,0-19.311142-8.7883911-19.311142-19.311142c0-10.2755737,8.3688812-18.9184723,18.62146-19.2896881
                                    C145.1227417,120.8428192,153.7514496,129.7540436,154.0867462,140.5321198c0.0998535,3.2098389,5.1002808,3.2238007,5,0
                                    c-0.4113617-13.223175-10.7686615-24.311142-24.311142-24.311142c-13.2330704,0-24.311142,11.078064-24.311142,24.311142
                                    c0,13.2330627,11.0780716,24.311142,24.311142,24.311142c13.5424805,0,23.8997803-11.0879669,24.311142-24.311142
                                    C159.1868744,137.3136597,154.1867676,137.3169708,154.0867462,140.5321198z"/>
                            </g>
                            <g>
                                <path fill="#EE3E3E" d="M132.2756042,109.8855972c0,6.4375076,0,12.8750229,0,19.3125229c0,3.2172699,5,3.2225952,5,0
                                    c0-6.4375,0-12.8750153,0-19.3125229C137.2756042,106.668335,132.2756042,106.663002,132.2756042,109.8855972
                                    L132.2756042,109.8855972z"/>
                            </g>
                            <g>
                                <path fill="#EE3E3E" d="M132.2756042,155.0548096c0,6.4375153,0,12.8750305,0,19.3125305c0,3.2172699,5,3.2225952,5,0
                                    c0-6.4375,0-12.8750153,0-19.3125305C137.2756042,151.8375549,132.2756042,151.8322144,132.2756042,155.0548096
                                    L132.2756042,155.0548096z"/>
                            </g>
                            <g>
                                <path fill="#EE3E3E" d="M102.534729,144.6264648c6.4375153,0,12.8750229,0,19.3125305,0c3.2172623,0,3.2225952-5,0-5
                                    c-6.4375076,0-12.8750153,0-19.3125305,0C99.3174667,139.6264648,99.3121414,144.6264648,102.534729,144.6264648
                                    L102.534729,144.6264648z"/>
                            </g>
                            <g>
                                <path fill="#EE3E3E" d="M147.703949,144.6264648c6.4375153,0,12.8750153,0,19.3125305,0c3.2172546,0,3.2225952-5,0-5
                                    c-6.4375153,0-12.8750153,0-19.3125305,0C144.4866791,139.6264648,144.4813538,144.6264648,147.703949,144.6264648
                                    L147.703949,144.6264648z"/>
                            </g>
                            <circle fill="#EE3E3E" cx="134.7756042" cy="142.1264648" r="2.7598696"/>
                        </g>
                        </svg>
                    <h4 class="text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        副乳
                    </h4>
                </div>
                <div class="col-sm-6 col-lg-4 d-flex flex-column align-items-center mb-4">
                    <svg class="icon arm mb-2" version="1.2" baseProfile="tiny" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 269.2913513 269.2913513" xml:space="preserve">
                        <g>
                            <circle fill="#FFFFFF" stroke="#576C80" stroke-width="6" stroke-miterlimit="10" cx="134.6456757" cy="134.6456757" r="127.1374512"></circle>
                            <g>
                                <path fill="#576C80" d="M167.727478,103.8544769c6.8317871-3.7889404,13.9836426-7.0303955,21.1789551-10.2116089
                                    c7.192627-3.2047729,14.4431152-6.2853394,21.6809082-9.3967285c11.194519-4.7377319,22.4359741-9.3825073,33.7042847-13.9788818
                                    c-0.1300659-0.2210693-0.2601929-0.4420776-0.3915405-0.6622314c-11.4979858,3.874939-22.9028931,8.0332031-34.1950684,12.4823608
                                    c-7.3583984,2.8621826-14.6477051,5.892395-21.9262695,8.9570313c-7.263916,3.1045532-14.5002441,6.3707886-21.4938965,10.1817017
                                    c-3.4853516,1.9223633-6.9069824,3.9797363-10.208252,6.2301636c-3.3095703,2.2434082-6.4816895,4.6651611-9.5515137,7.1713257
                                    c-6.1333008,5.0284424-12.0102539,10.2385254-18.3037109,14.8804321
                                    c-6.2966309,4.6530762-12.9284668,8.8786011-19.7026367,12.880249
                                    c-6.7412109,4.0668945-13.6887207,7.8018799-20.5490723,11.7040405
                                    c14.2746582-6.6790771,28.5710449-13.5619507,41.6166992-22.682312
                                    c6.5715332-4.4992065,12.5507813-9.7290039,18.7160645-14.5665894c2.4985352-1.9727783,5.0150757-3.9001465,7.6038818-5.7113037
                                    c-1.3262329,0.1891479-2.647522,0.3922729-3.975708,0.5755615c1.7161865-0.4849243,3.4396973-0.9404907,5.1638184-1.3933716
                                    c0.2081299-0.1411133,0.4122925-0.2885742,0.621582-0.4279175
                                    C160.9428101,107.7125092,164.3012085,105.7258148,167.727478,103.8544769z"></path>
                                <path fill="#576C80" d="M204.5126343,178.4156342c-20.5864258-0.6868896-41.1645508-1.6112061-61.6887207-3.1917725
                                    c-20.5187988-1.6104126-41.0083008-3.5838013-61.3647461-6.5350952
                                    c-5.0944824-0.6957397-10.1599121-1.5796509-15.2409668-2.3599854l-15.1730957-2.7219238
                                    c-4.9855957-0.9750977-9.942627-2.171814-13.9003906-4.9194336c-3.9416504-2.7121582-6.6743164-7.0039063-8.0876465-11.6587524
                                    c-1.4626465-4.6655884-1.5454102-9.710083-0.7995605-14.6375732c0.7573242-4.9196167,2.265625-9.838562,4.135498-14.4851685
                                    c0.9665527-2.298645,2.1015625-4.5048828,3.5563965-6.4435425c1.4387207-1.9389038,3.230957-3.6095581,5.2260742-5.0874634
                                    c1.9916992-1.4821167,4.1584473-2.7857666,6.3747559-4.06604l6.7373047-3.7086182
                                    c17.982666-9.8986206,37.1003418-17.5893555,56.3427734-24.8275146
                                    c19.2851563-7.1715088,38.902832-13.4959145,58.3762207-20.2576332c9.7182617-3.4375,19.4291992-6.9066162,29.0200195-10.6933594
                                    c5.637146-2.1498413,11.2035522-4.4772339,16.7298584-6.8939819c-0.1083374-0.0880127-0.2175903-0.1750488-0.3262329-0.2627563
                                    c-15.0866699,6.06073-30.4325562,11.4405518-45.8926392,16.465332
                                    c-19.5541992,6.4694824-39.2490234,12.5530434-58.6916504,19.4832802
                                    c-4.8552246,1.7468262-9.7158203,3.4811401-14.5227051,5.3705444c-4.8383789,1.8040161-9.590332,3.8342285-14.357666,5.8255005
                                    c-2.3969727,0.9642944-4.734375,2.071167-7.0922852,3.1292725c-2.348877,1.0787964-4.7214355,2.1057739-7.0507813,3.227417
                                    l-6.953125,3.442688c-1.1552734,0.5806885-2.3254395,1.1325684-3.46875,1.7369995l-3.4086914,1.8567505
                                    c-4.5310059,2.5140991-9.152832,4.7592773-13.4802246,7.928833c-2.1477051,1.5749512-4.1804199,3.4389648-5.8242188,5.6352539
                                    c-1.6494141,2.1807251-2.8928223,4.5910034-3.9316406,7.0302124c-1.0268555,2.4523315-1.8659668,4.9173584-2.6223145,7.4352417
                                    c-0.7463379,2.5184937-1.3659668,5.0911865-1.7709961,7.7161255c-0.8164063,5.2261963-0.7751465,10.7537842,0.8300781,15.9598999
                                    c1.5749512,5.1586914,4.682373,10.0494995,9.2868652,13.1962891c2.2797852,1.5604858,4.8005371,2.6655884,7.3447266,3.47052
                                    c2.548584,0.8093262,5.1276855,1.3591309,7.677002,1.8465576c5.0898438,0.9130249,10.1694336,1.8067017,15.2609863,2.6682739
                                    c5.1052246,0.7613525,10.1960449,1.6260986,15.3127441,2.302002c20.4504395,2.8209839,40.9992676,4.8840332,61.5900879,6.1339722
                                    c20.5878906,1.3040161,41.2011719,1.970459,61.8095703,2.3598022c16.3013916,0.2233276,32.5996704,0.3244629,48.8977051,0.3053589
                                    c0.0473022-0.12323,0.093811-0.2467041,0.140686-0.3700562
                                    C237.175293,179.4087982,220.8386841,179.0344696,204.5126343,178.4156342z"></path>
                                <path fill="#576C80" d="M155.906311,111.1321259c7.616272-1.0861206,15.2086792-2.2416382,22.7969971-3.3858643
                                    l13.3640137-1.9856567l6.6801758-0.9904785l6.685791-0.9484863l6.684082-0.9624023l6.6899414-0.9213867l13.3857422-1.8347168
                                    c7.951416-1.072937,15.9104614-2.1201172,23.8740845-3.2648315c-0.0374146-0.1201782-0.0787964-0.2387085-0.1165161-0.3587646
                                    c-8.019043,0.1559448-16.0253296,0.6231079-24.0126953,1.305542c-4.5036621,0.3627319-8.9931641,0.8626709-13.4858398,1.3426514
                                    c-4.4873047,0.5238037-8.965332,1.1217651-13.442627,1.725769c-4.4682617,0.6687622-8.9335938,1.3546753-13.388916,2.1018066
                                    c-4.4470215,0.7990112-8.8977051,1.5716553-13.3227539,2.4852905c-7.1101685,1.4291992-14.1862793,3.0306396-21.2033691,4.8737183
                                    c-1.7241211,0.4528809-3.4476318,0.9084473-5.1638184,1.3933716
                                    C153.2587891,111.5243988,154.5800781,111.3212738,155.906311,111.1321259z"></path>
                            </g>
                        </g>
                        <g class="crosshair">
                            <path fill="#EE3E3E" d="M195.1139984,141.0607758c-0.3271484,10.5160828-8.5358429,19.311142-19.311142,19.311142
                                    c-10.5229492,0-19.311142-8.7883759-19.311142-19.311142c0-10.2755585,8.3688812-18.9184647,18.62146-19.2896805
                                    C186.1499939,121.3714828,194.7787018,130.2827148,195.1139984,141.0607758c0.0998535,3.2098541,5.100296,3.2238007,5,0
                                    c-0.4113617-13.2231674-10.7686615-24.3111343-24.311142-24.3111343c-13.2330627,0-24.311142,11.078064-24.311142,24.3111343
                                    s11.0780792,24.311142,24.311142,24.311142c13.5424805,0,23.8997803-11.0879669,24.311142-24.311142
                                    C200.2141266,137.8423157,195.2140198,137.8456268,195.1139984,141.0607758z"></path>
                            <path fill="#EE3E3E" d="M173.3028564,110.4142609c0,6.4375076,0,12.8750229,0,19.3125305c0,3.2172546,5,3.2225952,5,0
                                    c0-6.4375076,0-12.8750229,0-19.3125305C178.3028564,107.1969986,173.3028564,107.1916733,173.3028564,110.4142609
                                    L173.3028564,110.4142609z"></path>
                            <path fill="#EE3E3E" d="M173.3028564,155.5834808c0,6.4375,0,12.8750153,0,19.3125305c0,3.2172546,5,3.22258,5,0
                                    c0-6.4375153,0-12.8750305,0-19.3125305C178.3028564,152.3662109,173.3028564,152.3608856,173.3028564,155.5834808
                                    L173.3028564,155.5834808z"></path>
                            <path fill="#EE3E3E" d="M143.5619812,145.1551361c6.4375153,0,12.8750305,0,19.3125305,0c3.2172699,0,3.2225952-5,0-5
                                    c-6.4375,0-12.8750153,0-19.3125305,0C140.3447266,140.1551361,140.3394012,145.1551361,143.5619812,145.1551361
                                    L143.5619812,145.1551361z"></path>
                            <path fill="#EE3E3E" d="M188.7312012,145.1551361c6.4375153,0,12.8750153,0,19.3125305,0c3.2172699,0,3.2225952-5,0-5
                                    c-6.4375153,0-12.8750153,0-19.3125305,0C185.5139465,140.1551361,185.508606,145.1551361,188.7312012,145.1551361
                                    L188.7312012,145.1551361z"></path>
                            <circle fill="#EE3E3E" cx="175.8028564" cy="142.6551361" r="2.7598696"></circle>
                        </g>
                    </svg>
                    <h4 class="text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        手臂
                    </h4>
                </div>
                <div class="col-sm-6 col-lg-4 breast d-flex flex-column align-items-center mb-4">
                    <svg class="icon mb-2" version="1.2" baseProfile="tiny" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 269.2913513 269.2913513" xml:space="preserve">
                        <g>
                            <circle fill="#FFFFFF" stroke="#576C80" stroke-width="6" stroke-miterlimit="10" cx="134.6456757" cy="134.6456757" r="127.1374512"></circle>
                            <g>
                                <path fill="transparent" d="M173.7528076,79.5760345c1.1982422,0.8755493,2.3308105,1.8217163,3.4108887,2.809021
                                    c2.1628418,1.9768677,4.1328125,4.0865479,6.1359863,6.1470947c3.9936523,4.1446533,8.0183105,8.2582397,12.0952148,12.3188477
                                    c2.029541,2.0518188,4.0761719,4.0328369,6.1179199,6.1165161c0.9934082,1.0706177,1.9313965,2.2335205,2.6054688,3.593811
                                    c0.6652832,1.343689,1.0080566,2.9983521,0.5354004,4.5440674l-0.1171875,0.383667l-0.4042969-0.0224609
                                    c-6.3601074-0.354187-12.4233398-2.8006592-17.7822266-6.0662842c-5.4006348-3.2557983-10.3271484-7.154541-15.2336426-10.9535522
                                    c-9.8449707-7.6131592-19.6074219-15.329895-29.3901367-23.0209961l-14.6235352-11.5677528
                                    c-2.4460449-1.8894653-4.8903809-3.7561035-7.4157715-5.4907227c-2.5263672-1.7374268-5.1308594-3.3691406-7.7924805-4.9104004
                                    c-5.3203125-3.0768433-10.8225098-5.9570313-16.4841309-8.2971191c-2.8364258-1.1607666-5.7294922-2.1680298-8.6838379-2.9187012
                                    c-2.0152588-0.5386353-4.0629272-0.9352417-6.1268311-1.2051392c3.480896,2.2011719,6.7039185,4.9338379,9.3822021,8.1098022
                                    c3.098877,3.6821899,5.583252,7.9017334,7.2502441,12.4396362c3.472168,9.0626259,3.3393555,19.361515,0.1889648,28.5127602
                                    c-1.5415039,4.605896-3.939209,8.907959-6.8979492,12.7641602c-2.9907227,3.8348389-6.5541992,7.1351929-10.3127441,10.0866699
                                    c-7.5581055,5.8297729-16.0231934,10.6088867-25.2185059,13.4329224
                                    c-4.5913086,1.3939819-9.3417969,2.340271-14.1535645,2.6061401c-2.4047852,0.1072388-4.8193359,0.137146-7.2202148-0.0869141
                                    c-1.217041-0.1390381-2.3254395-0.1030273-3.776123-0.5558472c-0.0576172-0.0180664-0.1164551-0.0478516-0.1746826-0.0692749
                                    c0.6340942,3.6350098,1.5234985,7.2186279,2.5904541,10.7436523c1.2053223,3.9991455,2.6096191,7.9356689,4.1757813,11.8048706
                                    c3.1376953,7.7354736,6.8640137,15.2313843,11.1425781,22.3792114
                                    c4.2927246,7.1630249,8.7993164,14.2282104,12.8693848,21.5894775c3.258667,5.9055786,6.2106323,12.0506592,8.3921509,18.4973755
                                    c0.5734253-1.8165894,1.4639282-3.5292969,2.5726929-5.0241699c2.1120605-2.883667,4.8544922-5.1599731,7.7836914-7.0248413
                                    c1.4709473-0.9302368,2.9995117-1.7597046,4.5766602-2.4813843c1.5810547-0.7393799,3.1958008-1.2853394,4.7941895-1.9008179
                                    c1.6103516-0.5766602,3.2485352-1.0757446,4.8859863-1.5653687c1.6557617-0.421875,3.2971191-0.911499,4.9748535-1.2325439
                                    c3.3308105-0.7729492,6.7160645-1.2277832,10.0979004-1.6429443c3.3898926-0.3273315,6.7883301-0.5827637,10.1867676-0.6269531
                                    c6.798584-0.1593628,13.5810547,0.2161255,20.3166504,0.8494873c6.7382813,0.6394043,13.4306641,1.4848633,20.1181641,2.5435791
                                    c6.6813965,1.0705566,13.3432617,2.4071655,19.8955078,4.2337646c6.5463867,1.8341064,12.9814453,4.171814,19.1252441,7.1716309
                                    c5.3228149,2.6010742,10.4032593,5.7506104,15.0535278,9.458374c0.4238281-3.0753174,1.5131226-6.019165,2.9262085-8.6665039
                                    c1.8383789-3.4728394,4.1411133-6.5755005,6.4343262-9.5947876c2.310791-3.0178833,4.5791016-5.960144,6.5708008-9.1068726
                                    c2.013916-3.1273193,3.7897949-6.3994751,5.2644043-9.7935791c2.9467773-6.7769775,4.5942383-14.1450195,4.137207-21.4985962
                                    c-0.2058105-3.6676025-0.9628906-7.2973022-2.2512207-10.7229004c-0.6630859-1.7058716-1.4650879-3.352478-2.3952637-4.927002
                                    c-0.9428711-1.5687256-2.0270996-3.0618286-3.2006836-4.4936523c-2.3493652-2.8667603-5.0449219-5.4832153-7.75-8.1240234
                                    c-2.720459-2.6311035-5.3820801-5.3758545-7.9125977-8.1995239c-5.0871582-5.6394043-9.8041992-11.5600586-14.6447754-17.3535156
                                    c-4.8447266-5.7852783-9.8461914-11.4525146-15.4665527-16.4784546
                                    c-5.2994385-4.7337646-11.2034912-8.861145-17.7406616-11.7489624c0.9389648,0.618042,1.9025879,1.2207642,2.8883667,1.8224487
                                    C171.3082275,77.9203339,172.552124,78.7022552,173.7528076,79.5760345z"></path>
                                <path fill="#576C80" d="M77.062561,38.956646c-1.4175415-0.8084717-2.8300781-1.6124878-4.1981812-2.4697876
                                    c-1.9677734-1.2391357-3.862793-2.6030884-5.6560059-4.0960693c-1.2269287-1.022644-2.3925171-2.1189575-3.5285645-3.2471313
                                    c-0.1559448,0.1051025-0.3099976,0.2127686-0.4654541,0.3185425c1.1055908,1.2236328,2.2497559,2.4138184,3.4742432,3.5245361
                                    c1.7460938,1.6002808,3.6071777,3.079895,5.5551758,4.4399414c1.4431152,1.0028076,2.9191895,1.9332275,4.3843994,2.8650513
                                    l0.2537842-0.7539063C76.9467163,39.345562,77.0011597,39.1500053,77.062561,38.956646z"></path>
                                <path fill="#576C80" d="M29.6672363,128.2759857c0.0582275,0.0214233,0.1170654,0.0512085,0.1746826,0.0692749
                                    c1.4506836,0.4528198,2.559082,0.4168091,3.776123,0.5558472c2.4008789,0.2240601,4.8154297,0.1941528,7.2202148,0.0869141
                                    c4.8117676-0.2658691,9.5622559-1.2121582,14.1535645-2.6061401c9.1953125-2.8240356,17.6604004-7.6031494,25.2185059-13.4329224
                                    c3.7585449-2.9514771,7.3220215-6.2518311,10.3127441-10.0866699c2.9587402-3.8562012,5.3564453-8.1582642,6.8979492-12.7641602
                                    c3.1503906-9.1512451,3.2832031-19.4501343-0.1889648-28.5127602c-1.6669922-4.5379028-4.1513672-8.7574463-7.2502441-12.4396362
                                    c-2.6782837-3.1759644-5.9013062-5.9086304-9.3822021-8.1098022c-0.9524536-0.1245728-1.9072266-0.2305298-2.8658447-0.2959595
                                    l-0.4526367-0.0309448c0.2960815,0.1895752,0.5968018,0.3777466,0.8903809,0.5690918
                                    c3.9526367,2.4707642,7.4504395,5.4924316,10.2978516,9.1091919c2.8217773,3.6101074,5.1091309,7.6516113,6.5673828,11.9851685
                                    c3.0180664,8.632328,2.8100586,18.2354774-0.2895508,26.7908363c-1.5107422,4.2914429-3.7443848,8.3145752-6.5288086,11.8928223
                                    c-2.8144531,3.5730591-6.2106934,6.6990356-9.8532715,9.5296631c-7.2822266,5.6777954-15.4433594,10.303772-24.2226563,13.0854492
                                    c-4.376709,1.4008179-8.8996582,2.3726196-13.4758301,2.6938477c-2.2866211,0.1375732-4.583252,0.194519-6.8681641,0.0258789
                                    c-1.1149902-0.0830688-2.3937988-0.0964966-3.1987305-0.3532715c-0.4160156-0.1304932-0.6843262-0.289856-0.9804688-0.59375
                                    c-0.2902832-0.2985229-0.5600586-0.7140503-0.8205566-1.1908569c-4.3024902-8.27771-8.5097656-16.7822266-13.5566397-24.7711182
                                    c-0.6286011-0.9802856-1.2710571-1.954834-1.9276733-2.9213257c-0.1758423,0.5609131-0.342041,1.1259766-0.5103149,1.6902466
                                    c0.3964233,0.6628418,0.7960815,1.3237915,1.1804199,1.9935913c4.7041006,8.1019287,8.5627432,16.6160889,12.7473135,25.1078491
                                    c0.2905273,0.5584106,0.6335449,1.1591187,1.1467285,1.7217407c0.4431152,0.5070801,1.0975342,0.9354248,1.7357178,1.1809082
                                    c-0.0670166-0.4794312-0.1450195-0.9571533-0.2030029-1.4379883
                                    C29.486145,127.3054047,29.5823975,127.7895355,29.6672363,128.2759857z"></path>
                                <path fill="#576C80" d="M80.5996094,41.0359306c2.0639038,0.2698975,4.1115723,0.6665039,6.1268311,1.2051392
                                    c2.9543457,0.7506714,5.8474121,1.7579346,8.6838379,2.9187012c5.6616211,2.3400879,11.1638184,5.2202759,16.4841309,8.2971191
                                    c2.6616211,1.5412598,5.2661133,3.1729736,7.7924805,4.9104004c2.5253906,1.7346191,4.9697266,3.6012573,7.4157715,5.4907227
                                    l14.6235352,11.5677528c9.7827148,7.6911011,19.545166,15.4078369,29.3901367,23.0209961
                                    c4.9064941,3.7990112,9.8330078,7.6977539,15.2336426,10.9535522c5.3588867,3.265625,11.4221191,5.7120972,17.7822266,6.0662842
                                    l0.4042969,0.0224609l0.1171875-0.383667c0.4726563-1.5457153,0.1298828-3.2003784-0.5354004-4.5440674
                                    c-0.6740723-1.3602905-1.6120605-2.5231934-2.6054688-3.593811c-2.041748-2.0836792-4.0883789-4.0646973-6.1179199-6.1165161
                                    c-4.0769043-4.0606079-8.1015625-8.1741943-12.0952148-12.3188477c-2.0031738-2.0605469-3.9731445-4.1702271-6.1359863-6.1470947
                                    c-1.0800781-0.9873047-2.2126465-1.9334717-3.4108887-2.809021c-1.2006836-0.8737793-2.4445801-1.6557007-3.6738281-2.4135742
                                    c-0.9857788-0.6016846-1.9494019-1.2044067-2.8883667-1.8224487c-0.3840942-0.1696777-0.762146-0.3512573-1.1506958-0.512146
                                    c0.329834,0.1251831,0.6524048,0.2689819,0.9798584,0.4003906c-1.3474731-0.8946533-2.6299438-1.8309326-3.7674561-2.8952026
                                    c-1.9853516-1.862854-3.597168-4.1162109-4.9978027-6.5115967c-1.3991699-2.4035072-2.5253906-4.9890785-3.5983887-7.6176186
                                    l-1.5292969-3.986145c-0.4604492-1.3469238-0.963623-2.6799927-1.3898926-4.0387573
                                    c-1.1854248-3.6443481-2.2189331-7.3397217-3.1066284-11.0720825c-0.1452026,0.1628418-0.2772827,0.336792-0.4255981,0.4969482
                                    c0.1168823-0.2727051,0.2317505-0.5421143,0.3482056-0.8137817c-0.4022827-1.7098999-0.7826538-3.4251709-1.1155396-5.1513672
                                    c-1.6418457-8.4511108-2.4899902-17.0661011-2.5687256-25.72052c-0.3104248-0.0247192-0.6212769-0.0477905-0.932312-0.0703125
                                    c-0.5188599,8.7178335-0.0830688,17.5197754,1.3394165,26.1758423c0.9501953,5.7238159,2.2663574,11.3965454,4.0068359,16.9423218
                                    c0.4199219,1.3911743,0.9177246,2.7573242,1.373291,4.1375122l1.517334,4.0889893
                                    c1.0510254,2.7157593,2.2736816,5.4025307,3.7736816,7.9730263c1.4907227,2.5708008,3.3435059,4.9932251,5.604248,7.0265503
                                    c2.2751465,2.0616455,4.9313965,3.4589844,7.4172363,4.859375c1.2509766,0.6987915,2.4760742,1.3991089,3.6367188,2.1699829
                                    c1.1599121,0.7729492,2.2768555,1.6274414,3.3615723,2.5346069c2.1704102,1.8121338,4.2255859,3.8223877,6.3227539,5.796814
                                    c4.1845703,3.9517822,8.3381348,7.9347534,12.4396973,11.9706421c2.0395508,2.0158081,4.1462402,4.0202026,6.1047363,6.0715942
                                    c0.9536133,1.0391235,1.8288574,2.1530762,2.4255371,3.3809814c0.5308228,1.0892334,0.7555542,2.2938232,0.5175171,3.416687
                                    c-5.9750977-0.5128784-11.564209-3.1055298-16.5663452-6.3691406c-5.1508789-3.3673096-9.8046875-7.4677734-14.6340332-11.4038696
                                    c-9.5957031-7.9251099-19.2741699-15.746582-28.932373-23.593689c-4.8317871-3.9313965-9.6506348-7.8289185-14.5698242-11.6991005
                                    c-4.9091797-3.8838501-10.1977539-7.4289551-15.6835938-10.4933472
                                    c-5.5109863-3.0393677-11.1557617-5.7620239-17.0568848-8.0254517c-2.9533691-1.1138916-5.9780273-2.0750122-9.0766602-2.7717896
                                    c-2.7224121-0.6182861-5.5090332-0.9668579-8.3029785-1.0975342c1.0331421-4.1156006,1.4152222-8.3789063,1.0710449-12.5748291
                                    c-0.1689453-2.0515747-0.4985962-4.086853-0.9762573-6.0814819c-0.2581177,0.1257324-0.5136719,0.2556152-0.770813,0.3830566
                                    c0.3233032,1.9071655,0.5141602,3.8323364,0.5480957,5.7589111c0.0768433,4.2548828-0.4865112,8.5098267-1.7637329,12.5321655
                                    c-0.0614014,0.1933594-0.1158447,0.388916-0.180603,0.5811768l-0.2537842,0.7539063l-0.1226807,0.3643188l0.7756348,0.0529785
                                    l0.4526367,0.0309448C78.6923828,40.8054008,79.6471558,40.9113579,80.5996094,41.0359306z"></path>
                                <path fill="#576C80" d="M148.6303101,39.1068535c2.2984009-2.5772095,4.2250366-5.4737549,5.8322144-8.5391235
                                    c1.7272949-3.2540283,3.0793457-6.7115479,4.0793457-10.2640381c0.9311523-3.3314209,1.5447998-6.7522573,1.8013306-10.196166
                                    c-0.7832031-0.1607666-1.5713501-0.3077393-2.3597412-0.4540405c-0.656311,3.3200684-1.4127197,6.6003408-2.3287964,9.8385
                                    c-0.9577637,3.411499-2.081543,6.772583-3.326416,10.1139526c-1.1608276,3.0662842-2.4521484,6.0963745-3.7753296,9.184082
                                    c-0.1164551,0.2716675-0.2313232,0.5410767-0.3482056,0.8137817
                                    C148.3530273,39.4436455,148.4851074,39.2696953,148.6303101,39.1068535z"></path>
                                <path fill="#576C80" d="M204.793335,76.9501801c0.3879395-1.9744873,0.765625-3.9522705,1.1174316-5.9349365l0.9436035-5.967041
                                    l0.7460938-5.9959145l0.5964355-6.0134888l0.3898926-6.0303345l0.2385254-6.0374146l0.0422363-6.0410156
                                    c-0.013855-1.1904297-0.0507202-2.3798828-0.0806885-3.5697021c-0.7954102-0.5719604-1.5981445-1.1343994-2.4071655-1.6882935
                                    c-0.0547485,1.7421875-0.1083374,3.4835815-0.133728,5.2241821l-0.2512207,5.9730835l-0.3310547,5.9667969l-0.454834,5.9575806
                                    l-0.5236816,5.9519043l-0.6481934,5.9406166l-0.7248535,5.9342651c-0.2954102,1.9706421-0.5651855,3.9460449-0.8244629,5.9248657
                                    c-1.1350708,7.7033081-2.5180054,15.3764038-3.9848022,23.0756836c0.0219727,0.024353,0.0440063,0.0487061,0.065979,0.0730591
                                    C201.1952515,92.285202,203.2210693,84.6639862,204.793335,76.9501801z"></path>
                                <path fill="#576C80" d="M224.7193604,199.4072113c2.2697754-2.9940186,4.6101074-6.059021,6.6484375-9.3147583
                                    c2.0627441-3.2387085,3.8908691-6.6420898,5.4118652-10.1901245c3.0693359-7.0662842,4.6948242-14.9036255,4.092041-22.671936
                                    c-0.2756348-3.8770752-1.1328125-7.7210083-2.5610352-11.3516235c-0.7329102-1.8082886-1.6159668-3.5565796-2.6340332-5.2218018
                                    c-1.029541-1.6570435-2.1967773-3.2129517-3.4406738-4.6860352c-2.4916992-2.9485474-5.276123-5.5664063-8.0158691-8.1651001
                                    c-2.7524414-2.5852661-5.3964844-5.2334595-7.9538574-8.0027466c-5.1071777-5.53302-9.9421387-11.3447876-14.9492188-17.0249023
                                    c-0.9083862-1.0332031-1.8255615-2.060791-2.7481689-3.0841064c-0.0219727-0.024353-0.0440063-0.0487061-0.065979-0.0730591
                                    c-4.1314087-4.5791626-8.4294434-9.0263672-13.1343384-13.0338745c-2.8977051-2.4466553-5.9260254-4.7520142-9.1608887-6.7329102
                                    c-2.930603-1.7963867-6.0056152-3.3486938-9.1878662-4.6259766c-0.3274536-0.1314087-0.6500244-0.2752075-0.9798584-0.4003906
                                    c0.3885498,0.1608887,0.7666016,0.3424683,1.1506958,0.512146c6.5371704,2.8878174,12.4412231,7.0151978,17.7406616,11.7489624
                                    c5.6203613,5.0259399,10.6218262,10.6931763,15.4665527,16.4784546
                                    c4.8405762,5.793457,9.5576172,11.7141113,14.6447754,17.3535156c2.5305176,2.8236694,5.1921387,5.5684204,7.9125977,8.1995239
                                    c2.7050781,2.6408081,5.4006348,5.2572632,7.75,8.1240234c1.173584,1.4318237,2.2578125,2.9249268,3.2006836,4.4936523
                                    c0.9301758,1.5745239,1.7321777,3.2211304,2.3952637,4.927002c1.2883301,3.4255981,2.0454102,7.0552979,2.2512207,10.7229004
                                    c0.4570313,7.3535767-1.1904297,14.7216187-4.137207,21.4985962c-1.4746094,3.394104-3.2504883,6.6662598-5.2644043,9.7935791
                                    c-1.9916992,3.1467285-4.2600098,6.0889893-6.5708008,9.1068726c-2.2932129,3.0192871-4.5959473,6.1219482-6.4343262,9.5947876
                                    c-1.4130859,2.6473389-2.5023804,5.5911865-2.9262085,8.6665039c-4.6502686-3.7077637-9.7307129-6.8572998-15.0535278-9.458374
                                    c-6.1437988-2.9998169-12.5788574-5.3375244-19.1252441-7.1716309c-6.5522461-1.8265991-13.2141113-3.163208-19.8955078-4.2337646
                                    c-6.6875-1.0587158-13.3798828-1.9041748-20.1181641-2.5435791c-6.7355957-0.6333618-13.5180664-1.0088501-20.3166504-0.8494873
                                    c-3.3984375,0.0441895-6.796875,0.2996216-10.1867676,0.6269531c-3.3818359,0.4151611-6.7670898,0.8699951-10.0979004,1.6429443
                                    c-1.6777344,0.3210449-3.3190918,0.8106689-4.9748535,1.2325439c-1.6374512,0.489624-3.2756348,0.9887085-4.8859863,1.5653687
                                    c-1.5983887,0.6154785-3.2131348,1.161438-4.7941895,1.9008179c-1.5771484,0.7216797-3.1057129,1.5511475-4.5766602,2.4813843
                                    c-2.9291992,1.8648682-5.6716309,4.1411743-7.7836914,7.0248413c-1.1087646,1.494873-1.9992676,3.2075806-2.5726929,5.0241699
                                    c-2.1815186-6.4467163-5.1334839-12.5917969-8.3921509-18.4973755
                                    c-4.0700684-7.3612671-8.5766602-14.4264526-12.8693848-21.5894775
                                    c-4.2785645-7.1478271-8.0048828-14.6437378-11.1425781-22.3792114c-1.5661621-3.8692017-2.970459-7.8057251-4.1757813-11.8048706
                                    c-1.0669556-3.5250244-1.9563599-7.1086426-2.5904541-10.7436523c-0.0848389-0.4864502-0.1810913-0.9705811-0.2559814-1.4589844
                                    c0.0579834,0.480835,0.1359863,0.9585571,0.2030029,1.4379883c1.0926514,7.817688,3.3457031,15.4503174,6.1104736,22.8458252
                                    c2.9545898,7.8530884,6.5070801,15.4857178,10.6398926,22.8085327c4.1384277,7.2947388,8.5803223,14.3886719,12.487793,21.7471313
                                    c3.9116211,7.3414307,7.3256836,14.9682617,9.3413086,22.9749146l0.9144287,3.6322632
                                    c-0.1767578-0.9838257-0.3416748-1.972229-0.4776611-2.972229c0.6918945,1.2011108,1.3154297,2.4284668,1.9050903,3.6681519
                                    l-0.194397-4.6495361c-0.1298828-3.1075439,1.0341797-6.1437988,2.9458008-8.6575317
                                    c1.9089355-2.5314941,4.4589844-4.6062622,7.2202148-6.3041992c1.3852539-0.8490601,2.8344727-1.5997314,4.3171387-2.2772217
                                    c1.4719238-0.6815796,3.0527344-1.2075806,4.5959473-1.7940063c1.5581055-0.5494995,3.1452637-1.0238647,4.7312012-1.4904175
                                    c1.6066895-0.3994751,3.1953125-0.8676147,4.826416-1.170166c3.2314453-0.7348022,6.529541-1.1598511,9.8251953-1.5492554
                                    c3.3085938-0.302124,6.6274414-0.5368652,9.9592285-0.5632935c6.6606445-0.1239624,13.3378906,0.2767944,19.9956055,0.9359741
                                    c13.3137207,1.3936157,26.6462402,3.2901611,39.4785156,6.8588867c6.418457,1.7642822,12.6953125,4.013855,18.6625977,6.8913574
                                    c5.9631348,2.8781738,11.5847168,6.4447021,16.6098633,10.7212524l1.2960815,1.1029053
                                    c0.09021-0.5300903,0.1776733-1.0586548,0.2727661-1.5906982c0.0974731,0.6713867,0.1641846,1.347229,0.2346802,2.022522
                                    l0.5307007,0.4515991l-0.0268555-3.0533447c-0.0305176-3.5077515,1.0632324-6.9799194,2.7556152-10.1914673
                                    C220.2576904,205.4252777,222.4571533,202.4120331,224.7193604,199.4072113z"></path>
                                <path fill="#576C80" d="M214.8049927,221.014328c-0.5578003,3.2786255-1.0526123,6.5172729-1.7980347,9.6603394
                                    c-0.4121704,1.7388916-0.8996582,3.4584351-1.4185791,5.1804199c1.1549683-0.8794556,2.2958374-1.7763062,3.4193115-2.6939087
                                    c0.1192627-0.692627,0.2260742-1.3876953,0.2971191-2.0879517c0.3803101-3.2227173,0.3400269-6.4432983,0.0076294-9.6270752
                                    c-0.0704956-0.675293-0.137207-1.3511353-0.2346802-2.022522
                                    C214.982666,219.9556732,214.8952026,220.4842377,214.8049927,221.014328z"></path>
                                <path fill="#576C80" d="M69.1081543,222.2636566c0.3877563,2.1585693,0.8565674,4.2858887,1.3719482,6.4000854
                                    c0.7319336,3.0827026,1.590332,6.1262207,2.53125,9.1478271c0.9482422,3.019043,1.9829102,6.0151367,3.1813965,8.9598999
                                    c0.1373901,0.3426514,0.2835083,0.6827393,0.4245605,1.024353c0.8297729,0.4263916,1.6660767,0.8414917,2.5062866,1.250061
                                    c-0.2243042-1.0007324-0.456543-1.9986572-0.7025757-2.9926758c-0.7424316-3.078125-1.6030273-6.1195068-2.5478516-9.1386108
                                    c-0.9516602-3.0166016-1.9814453-6.0126953-3.1711426-8.9591675c-0.6686401-1.6825562-1.3842163-3.3512573-2.1664429-4.9958496
                                    c-0.5896606-1.2396851-1.2131958-2.467041-1.9050903-3.6681519
                                    C68.7664795,220.2914276,68.9313965,221.2798309,69.1081543,222.2636566z"></path>
                            </g>
                        </g>
                        <g class="crosshair">
                                <path fill="#EE3E3E" d="M162.5596619,143.3217316c-0.3271484,10.5160828-8.5358429,19.311142-19.311142,19.311142
                                    c-10.5229492,0-19.3111343-8.7883759-19.3111343-19.311142c0-10.2755585,8.3688736-18.9184647,18.6214523-19.2896805
                                    C153.5956573,123.6324387,162.2243652,132.5436707,162.5596619,143.3217316c0.0998535,3.2098541,5.100296,3.2238007,5,0
                                    c-0.4113617-13.223175-10.7686615-24.3111343-24.311142-24.3111343c-13.2330627,0-24.3111343,11.0780716-24.3111343,24.3111343
                                    c0,13.233078,11.0780716,24.311142,24.3111343,24.311142c13.5424805,0,23.8997803-11.0879669,24.311142-24.311142
                                    C167.65979,140.1032715,162.6596832,140.1065826,162.5596619,143.3217316z"></path>
                            <path fill="#EE3E3E" d="M140.7485199,112.6752167c0,6.4375076,0,12.8750229,0,19.3125305c0,3.2172546,5,3.2225952,5,0
                                    c0-6.4375076,0-12.8750229,0-19.3125305C145.7485199,109.4579544,140.7485199,109.4526291,140.7485199,112.6752167
                                    L140.7485199,112.6752167z"></path>
                            <path fill="#EE3E3E" d="M140.7485199,157.8444366c0,6.4375,0,12.8750153,0,19.3125305c0,3.2172546,5,3.22258,5,0
                                    c0-6.4375153,0-12.8750305,0-19.3125305C145.7485199,154.6271667,140.7485199,154.6218414,140.7485199,157.8444366
                                    L140.7485199,157.8444366z"></path>
                            <path fill="#EE3E3E" d="M111.0076523,147.4160919c6.4375076,0,12.8750153,0,19.3125229,0c3.2172699,0,3.2225952-5,0-5
                                    c-6.4375076,0-12.8750153,0-19.3125229,0C107.79039,142.4160919,107.7850571,147.4160919,111.0076523,147.4160919
                                    L111.0076523,147.4160919z"></path>
                            <path fill="#EE3E3E" d="M156.1768646,147.4160919c6.4375153,0,12.8750153,0,19.3125305,0c3.2172699,0,3.2225952-5,0-5
                                    c-6.4375153,0-12.8750153,0-19.3125305,0C152.95961,142.4160919,152.9542694,147.4160919,156.1768646,147.4160919
                                    L156.1768646,147.4160919z"></path>
                            <circle fill="#EE3E3E" cx="143.2485199" cy="144.9160919" r="2.7598696"></circle>
                        </g>
                    </svg>
                    <h4 class="text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        胸部
                    </h4>
                </div>

                <div class="col-sm-6 col-lg-3 d-flex flex-column align-items-center mb-4">
                    <svg class="icon waist mb-2" version="1.2" baseProfile="tiny" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 269.2913513 269.2913513" xml:space="preserve">
                        <g>
                            <circle fill="#FFFFFF" stroke="#576C80" stroke-width="6" stroke-miterlimit="10" cx="134.6456757" cy="134.6456757" r="127.1374512"></circle>
                            <g>
                                <path fill="#576C80" d="M195.2179565,115.1227875c1.303772-5.0764771,2.3153687-10.2185669,3.1399536-15.3745117
                                    c0.8514404-5.6506958,1.5111084-11.312561,2.1013794-16.9620361c0.5620728-5.6529541,1.1387939-11.286438,1.8319092-16.8918457
                                    c1.4237671-11.1881752,3.6778564-22.314518,8.3121338-32.7299232c-2.8779907,4.9099121-5.0605469,10.2322388-6.7749634,15.6865234
                                    c-1.6887817,5.4672852-2.9082031,11.0731201-3.8441772,16.7026405
                                    c-1.892334,11.2650757-2.8076782,22.5939331-4.5888062,33.7333984
                                    c-1.6881104,11.1465454-4.2744141,22.1340332-8.3840942,32.6034546
                                    c-4.055481,10.4978638-9.7845459,20.3036499-16.4191895,29.5327759
                                    c7.6057129-8.439209,14.0343628-18.0900269,18.5810547-28.6343994c2.3289185-5.244751,4.1571045-10.7040405,5.6837158-16.230835
                                    c0.125-0.4642944,0.2320557-0.9325562,0.3517456-1.3980103c-0.0332642-0.1078491-0.0637207-0.2163696-0.0971069-0.3241577
                                    C195.1481323,114.931076,195.1814575,115.0275726,195.2179565,115.1227875z"></path>
                                <path fill="#576C80" d="M190.6915283,220.2419891c-4.0780029-16.2470703-8.9447632-32.3469849-15.3753052-47.857666
                                    c-6.4102783-15.4714355-14.4033203-30.4992065-25.2167358-43.3017578
                                    c4.9129639,6.7655029,9.243042,13.9061279,12.9762573,21.3469849c3.7919922,7.4083252,7.085083,15.0582886,10.0744629,22.8190308
                                    c2.9523926,7.7765503,5.6464844,15.652832,8.0343018,23.6265259c2.4169312,7.9641113,4.616394,15.9979248,6.5969849,24.0888062
                                    c2.0337524,8.0779419,3.8536987,16.2122803,5.5499268,24.3745117c0.1350098,0.6334229,0.2614136,1.2686768,0.394043,1.9026489
                                    c0.8689575-0.4569092,1.7320557-0.9232178,2.5889893-1.3994751
                                    C194.6464233,237.2684174,192.7869873,228.7294769,190.6915283,220.2419891z"></path>
                                <path fill="#576C80" d="M212.6508789,190.642746l-1.5057983-12.9360352l-2.0579834-12.8562012
                                    c-3.071106-16.9799194-7.7089233-33.6652222-13.8691406-49.7277222c-0.036499-0.0952148-0.0698242-0.1917114-0.1064453-0.2869263
                                    c0.0333862,0.1077881,0.0638428,0.2163086,0.0971069,0.3241577c5.0687866,16.4260864,8.9165649,33.1694946,11.5791016,50.0795898
                                    c2.6428833,17.0272217,4.0668335,34.2294922,4.2432251,51.447937l-0.0488281,12.914917l-0.2703857,6.9246826
                                    c1.0136108-0.7579956,2.0112305-1.5358276,3.0010986-2.3230591c0.0654297-1.5099487,0.1295776-3.0199585,0.1998291-4.5297241
                                    l0.116272-13.0231934l-0.4353638-13.0177612L212.6508789,190.642746z"></path>
                                <path fill="#576C80" d="M177.6672363,73.9718475c0.7576294-2.3619385,1.3435059-5.0819092,0.6303101-7.5596313
                                    c-0.5631714,2.4705811-1.6430664,4.513916-2.7945557,6.6419067c-1.1309814,2.1210938-2.3761597,4.335022-3.055603,6.928772
                                    c-0.6657715,2.5595093-0.7373657,5.2563477-0.2351685,7.8111572c0.513916,2.546936,1.5764771,4.9871216,3.262146,6.8895264
                                    c-0.5570068-2.467041-0.8974609-4.8151245-0.9172363-7.1462402c-0.0320435-2.3234863,0.2293091-4.6038208,0.7926636-6.7990723
                                    C175.9054565,78.5649261,176.8895264,76.3655243,177.6672363,73.9718475z"></path>
                                <path fill="#576C80" d="M32.3032837,100.7602386c3.7290039-10.9550171,10.0681763-20.9127808,16.3878174-30.756897
                                    c1.5786133-2.4836426,3.2310181-4.8652344,4.7727661-7.4292641c1.5667114-2.5263062,2.913269-5.1752319,4.185791-7.8503418
                                    c2.4820557-5.3849487,4.3446655-11.013916,5.8960571-16.6962891c0.9312134-3.4533691,1.7371216-6.9306641,2.4697266-10.4210815
                                    c-0.3403931,0.218689-0.6752319,0.4451904-1.0133667,0.6669922c-0.7732544,3.1687622-1.6090088,6.3198853-2.5512695,9.4400635
                                    c-1.6889038,5.598877-3.739624,11.0787964-6.2397461,16.3309326c-1.2954712,2.6021729-2.6586914,5.1713867-4.2312012,7.6083984
                                    c-1.5259399,2.4432411-3.2625122,4.8607216-4.876709,7.3036537c-3.2752075,4.8811646-6.5172729,9.8007202-9.4946289,14.9118042
                                    c-2.9666748,5.1101685-5.6802979,10.4286499-7.6867065,16.0603638c-2.010376,5.6253662-3.2823486,11.5284424-3.8200073,17.4741821
                                    c-0.2741699,2.9772339-0.3952637,5.9447632-0.362915,8.9400635c0.0501099,3.0020142,0.2515259,5.9815674,0.6311646,8.9282837
                                    c0.7202148,5.9006348,1.8638916,11.7138062,3.197876,17.4728394c1.3330688,5.7603149,2.9436035,11.4465332,4.725647,17.0730591
                                    c1.7852783,5.6256714,3.7382813,11.1951294,5.8627319,16.6968384c2.1394043,5.4960327,4.4295654,10.930481,6.8525391,16.3035278
                                    c4.8980103,10.7235107,10.4237061,21.1486206,16.4490967,31.2608032c1.9581299,3.3493042,4.0524902,6.6152344,6.1928101,9.848938
                                    c0.7329712,0.4368896,1.4691162,0.8688965,2.2114868,1.2913208c-2.3851929-3.9557495-4.7219849-7.9384766-6.9705811-11.9685059
                                    c-5.7318726-10.2166748-10.8460083-20.7620239-15.5112915-31.4859009
                                    c-2.3260498-5.3655396-4.5182495-10.7874146-6.5587158-16.263916c-2.0545044-5.4708252-3.9794922-10.9898071-5.713562-16.5664673
                                    c-3.4205933-11.1542969-6.3718262-22.5030518-7.8794556-34.0167236c-0.3759766-2.875061-0.6369629-5.7575073-0.7184448-8.6235962
                                    c-0.0690918-2.8740234,0.0308838-5.7816162,0.2672729-8.6522827
                                    C29.2442627,111.8879242,30.4195557,106.2145233,32.3032837,100.7602386z"></path>
                            </g>
                        </g>
                        <g class="crosshair">
                            <path fill="#EE3E3E" d="M190.3236847,72.927002c-0.3271332,10.5160751-8.5358429,19.3111343-19.311142,19.3111343
                                    c-10.522934,0-19.311142-8.7883759-19.311142-19.3111343c0-10.2755699,8.3688812-18.9184723,18.62146-19.2896881
                                    C181.3596802,53.2377014,189.9884033,62.1489296,190.3236847,72.927002c0.0998535,3.2098389,5.100296,3.223793,5,0
                                    c-0.4113464-13.2231789-10.7686615-24.3111458-24.311142-24.3111458c-13.2330627,0-24.311142,11.0780716-24.311142,24.3111458
                                    s11.0780792,24.3111343,24.311142,24.3111343c13.5424805,0,23.8997955-11.0879669,24.311142-24.3111343
                                    C195.4238129,69.7085419,190.4237061,69.7118454,190.3236847,72.927002z"></path>
                            <path fill="#EE3E3E" d="M168.5125427,42.2804756c0,6.4375114,0,12.8750229,0,19.3125343c0,3.2172623,5,3.2225876,5,0
                                    c0-6.4375114,0-12.8750229,0-19.3125343C173.5125427,39.0632172,168.5125427,39.057888,168.5125427,42.2804756
                                    L168.5125427,42.2804756z"></path>
                            <path fill="#EE3E3E" d="M168.5125427,87.4496918c0,6.4375153,0,12.8750229,0,19.3125305c0,3.2172623,5,3.2225952,5,0
                                    c0-6.4375076,0-12.8750153,0-19.3125305C173.5125427,84.2324295,168.5125427,84.2271042,168.5125427,87.4496918
                                    L168.5125427,87.4496918z"></path>
                            <path fill="#EE3E3E" d="M138.7716675,77.0213547c6.4375153,0,12.8750305,0,19.3125458,0c3.2172546,0,3.22258-5,0-5
                                    c-6.4375153,0-12.8750305,0-19.3125458,0C135.5544128,72.0213547,135.5490875,77.0213547,138.7716675,77.0213547
                                    L138.7716675,77.0213547z"></path>
                            <path fill="#EE3E3E" d="M183.9408875,77.0213547c6.4375153,0,12.8750305,0,19.3125305,0c3.2172699,0,3.2225952-5,0-5
                                    c-6.4375,0-12.8750153,0-19.3125305,0C180.7236328,72.0213547,180.7183075,77.0213547,183.9408875,77.0213547
                                    L183.9408875,77.0213547z"></path>
                            <circle fill="#EE3E3E" cx="171.0125427" cy="74.5213547" r="2.7598696"></circle>
                        </g>
                    </svg>
                    <h4 class="text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        腰腹
                    </h4>
                </div>
                <div class="col-sm-6 col-lg-3 d-flex flex-column align-items-center mb-4">
                    <svg class="icon back mb-2" version="1.2" baseProfile="tiny" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 269.2913513 269.2913513" xml:space="preserve">
                        <g>
                            <circle fill="#FFFFFF" stroke="#576C80" stroke-width="6" stroke-miterlimit="10" cx="134.6456757" cy="134.6456757" r="127.1374512"></circle>
                            <g>
                                <path fill="#576C80" d="M179.4295654,42.9351006c-0.0680542,0.4777832-0.1514893,0.9302979-0.2404175,1.3739014
                                    c0.2994385-0.1330566,0.5992432-0.2654419,0.8920288-0.4129639c1.6242676-0.7021484,3.1464844-1.5891724,4.6877441-2.4353638
                                    c1.4829102-0.9375,3.005127-1.8190918,4.3989258-2.8773804c2.8659668-2.0091553,5.5275879-4.2667847,8.0944824-6.6040039
                                    c1.7280273-1.6188354,3.3928833-3.2929688,5.0319214-4.9892578c-0.2923584-0.184082-0.5852661-0.3670654-0.8792114-0.5488281
                                    c-1.6696777,1.5239258-3.3616333,3.019104-5.1190186,4.4294434c-2.6481934,2.1710205-5.3547363,4.2696533-8.2319336,6.0855103
                                    c-1.4077148,0.9574585-2.9013672,1.7642212-4.3615723,2.619751c-1.5097656,0.7614746-2.9980469,1.5650635-4.5668945,2.1785278
                                    c-1.5300293,0.7098389-3.1467285,1.1761475-4.7294922,1.7173462c-1.6223145,0.4051514-3.2255859,0.8859863-4.8820801,1.0975342
                                    c-1.6345215,0.3465576-3.3066406,0.3643799-4.9602051,0.5135498c-1.6608887,0.0252075-3.324707-0.0294189-4.9846191-0.2029419
                                    c-6.6591797-0.6690674-13.142334-2.8571167-19.5522461-5.2678833c-6.4475098-2.4011841-12.8994141-4.8458862-19.2775879-7.394104
                                    c-6.3933105-2.5234985-12.6901855-5.2562866-18.8625488-8.2371216c-4.7550659-2.3294067-9.421936-4.8433228-13.9262695-7.6265869
                                    c-0.6213379,0.2454224-1.2368164,0.5023804-1.8532715,0.7572632c4.7070923,3.2976685,9.6408691,6.2723999,14.7229004,8.9482422
                                    c12.2895508,6.5022583,25.2546387,11.446228,38.1503906,16.3619385c6.4882813,2.3834839,13.217041,4.6563721,20.3364258,5.2868042
                                    c1.7749023,0.1487427,3.5637207,0.1724243,5.3503418,0.1069946c1.776123-0.1890869,3.5683594-0.2557373,5.3100586-0.6570435
                                    c1.7668457-0.2695313,3.4672852-0.8024292,5.1772461-1.2839355c0.586731-0.2213745,1.1762695-0.4361572,1.7643433-0.6539307
                                    C177.6086426,44.3374443,178.4158936,43.5331841,179.4295654,42.9351006z"></path>
                                <path fill="#576C80" d="M92.1575928,237.9369354c0.1745605-4.6485596,0.190918-9.2975464,0.253418-13.9466553
                                    c0.0229492-4.6490479-0.0212402-9.2975464-0.0551758-13.946228c-0.105957-4.647583-0.1906738-9.2955322-0.3547363-13.9418335
                                    c-0.1955566-4.6453857-0.3513184-9.2918091-0.659668-13.9332886c-0.5571289-9.2807617-1.2854004-18.571167-2.6721191-27.7802124
                                    l0.6887207,55.6989746l0.7312622,43.6605835c0.4284668,0.1603394,0.8567505,0.321106,1.2874756,0.4769897
                                    C91.7597046,248.8009491,91.9905396,243.3666229,92.1575928,237.9369354z"></path>
                                <path fill="#576C80" d="M176.4666748,53.8470268l1.1008301-3.4796143
                                    c0.3598633-1.1641846,0.7072754-2.3401489,1.0324707-3.5549316c0.2102661-0.8101196,0.4145508-1.6323242,0.5891724-2.503479
                                    c0.0889282-0.4436035,0.1723633-0.8961182,0.2404175-1.3739014c-1.0136719,0.5980835-1.8209229,1.4023438-2.5105591,2.2854614
                                    c-0.1526489,0.1954346-0.3059082,0.390625-0.4472046,0.5929565c-0.7695313,1.1256104-1.362793,2.3626709-1.769043,3.6661377
                                    c-0.4055176,1.3024902-0.6237793,2.6793213-0.5771484,4.0773926c0.0293579,0.6922607,0.1218872,1.4067383,0.317688,2.092041
                                    c0.4223022,0.2307129,0.8424683,0.4648438,1.2602539,0.7045288
                                    C175.9537354,55.4738579,176.2088013,54.664959,176.4666748,53.8470268z"></path>
                                <path fill="#576C80" d="M205.404541,139.1877899c0.8547974-7.8726807,1.3971558-15.7893066,1.4390869-23.7443848
                                    c-0.0109863-6.2538452-0.4179688-12.5576782-1.6279297-18.7663574c-1.2104492-6.1924438-3.2412109-12.3187866-6.449707-17.8329468
                                    c-5.477478-9.4857178-13.6836548-17.109684-23.062439-22.4904823c-0.4177856-0.2396851-0.8379517-0.4738159-1.2602539-0.7045288
                                    c-1.1463623-0.6262817-2.3049927-1.2275391-3.4810181-1.7868652c-11.3815918-5.4412842-23.8945313-7.9224243-36.3146973-8.5322876
                                    c-3.1130371-0.1326294-6.2265625-0.1471558-9.3349609-0.0789185c-3.104248,0.085083-6.2150879,0.1883545-9.3200684,0.5921631
                                    c-3.0983887,0.4108276-6.1462402,1.1778564-9.0664063,2.2531738c-2.9147949,1.0957642-5.7094727,2.4541016-8.4462891,3.9039307
                                    c5.5876465-2.6500854,11.4682617-4.8248291,17.604248-5.3690796c3.0537109-0.2855835,6.1484375-0.277771,9.2351074-0.2507324
                                    c3.0856934,0.0437012,6.1679688,0.1696167,9.2385254,0.4117432c12.2592773,0.8973999,24.4829102,3.6098022,35.3862305,9.1309814
                                    c5.4365234,2.7608032,10.5083008,6.2115479,15.0253906,10.2613564c2.2614746,2.0231934,4.3344727,4.2434692,6.2485352,6.581604
                                    c1.9194336,2.3355103,3.6254883,4.8405151,5.0837402,7.4755859c2.9675293,5.2419434,4.9067383,11.0324097,6.0385742,16.9905396
                                    c1.1396484,5.9641724,1.5178223,12.0843506,1.505127,18.2116089c-0.0151367,12.2835083-1.2709961,24.5845947-2.9914551,36.8045654
                                    c-1.6794434,12.2368164-3.953125,24.407959-5.9355469,36.6466064
                                    c-2.0102539,12.2314453-3.9067383,24.5144653-4.9782715,36.8934937
                                    c-0.6204834,7.8840942-0.9417725,15.8123169-0.7272949,23.715332c0.2044678-0.0973511,0.4111938-0.1906738,0.6150513-0.2890625
                                    c0.0991821-7.7857056,0.6547852-15.5581055,1.5753784-23.2821655c1.3620605-12.2850342,3.5039063-24.4708862,5.7873535-36.6375122
                                    c2.3137207-12.1624756,4.6069336-24.3517456,6.4743652-36.6405029c0.6342163-4.2904053,1.1990356-8.5961304,1.6760864-12.9143677
                                    c-0.4091797-0.8204956-0.8203735-1.6401367-1.2009888-2.4735107
                                    C204.5557861,137.9124603,204.9818115,138.5486298,205.404541,139.1877899z"></path>
                                <path fill="#576C80" d="M228.2432861,218.5707855c-1.2168579,2.2671509-2.6500244,4.4257202-4.2290649,6.4933472
                                    c2.7398682-2.708252,5.3551025-5.5412598,7.8410034-8.4876709c1.7012329-3.6455688,2.8094482-7.5875854,3.2525635-11.5875244
                                    c0.6315918-5.170105,0.182373-10.3972778-0.8378906-15.4381714c-1.0375977-5.0507813-2.7746582-9.9135742-4.8918457-14.5661011
                                    c-1.0466309-2.3339844-2.2668457-4.5888062-3.5344238-6.7893677c-1.2878418-2.1932983-2.5981445-4.3760376-4.0412598-6.4641113
                                    c-1.4331055-2.0938721-2.9172363-4.1450806-4.4279785-6.1628418l-4.5749512-5.9926147
                                    c-2.5888062-3.3704224-5.0430908-6.8322754-7.3948975-10.3879395c-0.4227295-0.6391602-0.8487549-1.2753296-1.2645264-1.9210205
                                    c0.3806152,0.833374,0.7918091,1.6530151,1.2009888,2.4735107c1.8411865,3.6921997,3.9393311,7.2644653,6.1747437,10.7346191
                                    c2.736084,4.2401733,5.6171875,8.3500977,8.213623,12.5963745c5.2189941,8.4555664,9.604248,17.4686279,11.6296387,27.0905762
                                    c1.0397949,4.7871094,1.4133301,9.7004395,0.9853516,14.5368042
                                    C231.9317627,209.5433807,230.5076904,214.2491913,228.2432861,218.5707855z"></path>
                            </g>
                        </g>
                        <g class="crosshair">
                            <path fill="#EE3E3E" d="M78.1437988,133.0513153c-0.3271408,10.5160828-8.5358505,19.311142-19.3111382,19.311142
                                    c-10.5229454,0-19.311142-8.7883759-19.311142-19.311142c0-10.2755661,8.3688812-18.9184723,18.6214561-19.2896881
                                    C69.1797943,113.3620224,77.8085098,122.2732468,78.1437988,133.0513153c0.0998611,3.2098389,5.1002884,3.2238007,5,0
                                    c-0.4113541-13.223175-10.7686615-24.311142-24.3111382-24.311142c-13.2330704,0-24.311142,11.0780716-24.311142,24.311142
                                    s11.0780716,24.311142,24.311142,24.311142c13.5424767,0,23.8997841-11.0879669,24.3111382-24.311142
                                    C83.243927,129.8328552,78.2438202,129.8361664,78.1437988,133.0513153z"></path>
                            <path fill="#EE3E3E" d="M56.3326607,102.4047928c0,6.4375153,0,12.8750229,0,19.3125305c0,3.2172623,5,3.2225952,5,0
                                    c0-6.4375076,0-12.8750153,0-19.3125305C61.3326607,99.1875305,56.3326607,99.1822052,56.3326607,102.4047928
                                    L56.3326607,102.4047928z"></path>
                            <path fill="#EE3E3E" d="M56.3326607,147.5740051c0,6.4375153,0,12.8750305,0,19.3125305c0,3.2172699,5,3.2225952,5,0
                                    c0-6.4375,0-12.8750153,0-19.3125305C61.3326607,144.3567505,56.3326607,144.3514252,56.3326607,147.5740051
                                    L56.3326607,147.5740051z"></path>
                            <path fill="#EE3E3E" d="M26.5917873,137.1456757c6.4375095,0,12.875021,0,19.3125324,0c3.2172585,0,3.2225876-5,0-5
                                    c-6.4375114,0-12.8750229,0-19.3125324,0C23.3745251,132.1456757,23.3691959,137.1456757,26.5917873,137.1456757
                                    L26.5917873,137.1456757z"></path>
                            <path fill="#EE3E3E" d="M71.7610016,137.1456757c6.4375153,0,12.8750229,0,19.3125305,0c3.2172623,0,3.2225952-5,0-5
                                    c-6.4375076,0-12.8750153,0-19.3125305,0C68.5437393,132.1456757,68.538414,137.1456757,71.7610016,137.1456757
                                    L71.7610016,137.1456757z"></path>
                            <circle fill="#EE3E3E" cx="58.8326607" cy="134.6456757" r="2.7598696"></circle>
                        </g>
                    </svg>
                    <h4 class="text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        後背
                    </h4>
                </div>
                <div class="col-sm-6 col-lg-3 d-flex flex-column align-items-center mb-4">
                    <svg class="icon knee mb-2" version="1.2" baseProfile="tiny" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 269.2913513 269.2913513" xml:space="preserve">
                        <g>
                            <circle fill="#FFFFFF" stroke="#576C80" stroke-width="6" stroke-miterlimit="10" cx="134.6456757" cy="134.6456757" r="127.1374512"></circle>
                            <g>
                                <path fill="#576C80" d="M185.7242432,251.0960541c0.1914673-0.0841064,0.3838501-0.1663818,0.5747681-0.2514038
                                    c0.5056763-6.9370728,0.9178467-13.8816528,1.3048096-20.8287964c0.4748535-10.4562988,0.8132324-20.907959,0.9777832-31.3879395
                                    c0.1594238-10.4748535-0.2749023-21.0803223-2.4230957-31.4962158
                                    c-1.0871582-5.1964111-2.7695313-10.3068848-4.7297363-15.1794434c-1.9238281-4.8980713-3.998291-9.6740723-5.6069336-14.5534668
                                    c-0.8293457-2.4313965-1.4768066-4.8953857-1.9790039-7.3739014c-0.4677734-2.4836426-0.6716309-4.9864502-0.6303711-7.475708
                                    c0.1269531-2.4814453,0.5129395-4.9429932,1.2717285-7.3043213c0.7563477-2.3637695,1.8515625-4.6262207,3.154541-6.7836914
                                    c5.3320313-8.6512451,13.7131348-15.2590332,20.623291-23.1950684
                                    c7.0378418-7.8093262,13.5280762-16.1025391,19.3398438-24.8403358c2.9949341-4.6017456,5.8341675-9.3031616,8.4820557-14.104248
                                    c-0.1836548-0.1901245-0.3692017-0.378479-0.5540771-0.5674438c-2.8713989,4.7314453-5.9028931,9.3588257-9.1335449,13.8442993
                                    c-6.020752,8.5070839-12.651123,16.5683632-19.8181152,24.1053505c-3.5673828,3.7895508-7.3496094,7.328125-11.081543,11.048584
                                    c-3.7102051,3.720459-7.3718262,7.6328125-10.2675781,12.2199707c-1.4389648,2.291748-2.6467285,4.7653809-3.496582,7.3789063
                                    c-0.854248,2.6147461-1.2990723,5.3540039-1.4462891,8.0911865c-0.0622559,2.7385254,0.1547852,5.4665527,0.6435547,8.1260986
                                    c0.5217285,2.6469727,1.1987305,5.2504883,2.0458984,7.772583c1.684082,5.0501709,3.8110352,9.8652344,5.7272949,14.6900635
                                    c0.9726563,2.411499,1.871582,4.8276367,2.6635742,7.2697754c0.8022461,2.4364014,1.423584,4.9313965,2.0161133,7.4337158
                                    c2.2563477,10.0622559,2.7133789,20.4855957,2.8913574,30.8961182c0.1159668,10.4328613,0.036377,20.892334-0.1401367,31.338501
                                    C186.0775757,237.0120697,185.9094238,244.0530243,185.7242432,251.0960541z"></path>
                                <path fill="#576C80" d="M134.6456909,7.5082216c-0.5640259,0-1.1246948,0.0140381-1.6869507,0.0213623
                                    c-0.4418335,0.5814209-0.8691406,1.1738281-1.3141479,1.7528687
                                    c-7.9729004,10.5355215-16.3679199,20.7374268-25.177002,30.5743408
                                    c-3.8029175,4.255249-7.6358032,8.5061646-11.3412476,12.8875122c-0.0411987,0.1973877-0.0796509,0.3945923-0.1211548,0.59198
                                    c0.0013428-0.1542969,0.0099487-0.3081665,0.0118408-0.4624023c-0.5483398,0.649292-1.102417,1.2927856-1.644165,1.9487305
                                    c-4.2370605,5.1223145-8.3198242,10.4229774-11.8547363,16.1296425c-3.527832,5.7001953-6.4782715,11.8354492-8.380127,18.3458252
                                    c-1.9072266,6.4967041-2.6791992,13.3869629-2.1367188,20.1407471c0.2578125,3.3746338,0.7463379,6.7241211,1.4660645,10.0057373
                                    c0.6931152,3.2889404,1.5524902,6.5305176,2.527832,9.7209473c1.920166,6.395752,4.2236328,12.6181641,6.3691406,18.8516846
                                    c2.1816406,6.2183838,4.0720215,12.5008545,5.6999512,18.8862305c1.6220703,6.3812256,3.0620117,12.8184814,4.4172363,19.2825928
                                    c2.6643066,12.9400635,5.1772461,25.9442139,7.7854004,38.9122314
                                    c2.3156128,11.1592407,4.5632324,22.3312378,6.9152222,33.4834442c0.0865479,0.0197754,0.1740112,0.0371094,0.2605591,0.0567017
                                    c-1.8626099-11.2794647-3.8308716-22.5392303-5.7304688-33.8117523
                                    c-2.3166504-13.0310059-4.5703125-26.0561523-6.967041-39.0892334
                                    c-2.3918457-13.0234375-5.3937988-26.0332031-9.6918945-38.635376c-2.0996094-6.2958984-4.3967285-12.4886475-6.25-18.7775879
                                    c-0.9477539-3.1386719-1.7727051-6.3016357-2.432373-9.487793c-0.6882324-3.1821289-1.1374512-6.3981934-1.3793945-9.6173096
                                    c-0.5375977-6.4405518,0.1687012-12.9090576,1.9594727-19.1075439c1.7775879-6.2071533,4.6157227-12.0998535,7.9816895-17.6784668
                                    c6.7888184-11.1784706,15.6987305-20.9901161,24.2646484-31.0650673
                                    c8.6337891-10.0616455,16.8708496-20.4698486,24.6230469-31.2167969c0.6241455-0.8782349,1.2416382-1.7610474,1.8607178-2.6428223
                                    C134.6692505,7.5086489,134.6575317,7.5082216,134.6456909,7.5082216z"></path>
                                <path fill="#576C80" d="M228.1331177,196.5755463c-0.8859863-3.2108154-1.8183594-6.4112549-2.8649902-9.5804443
                                    c-1.026123-3.1759033-2.125-6.3308105-3.3666992-9.4577637c-2.4709473-6.3272705-6.1481934-12.140625-10.2165527-17.4401855
                                    c-2.0302734-2.6451416-3.9973145-5.2243652-5.6767578-7.9807129c-1.6936035-2.7445068-3.0778809-5.6676025-4.2705078-8.6918945
                                    c-2.3571777-6.036377-3.7741699-12.4472656-4.4042969-18.961792c-0.6525879-6.5169678-0.6188965-13.123291-0.2426758-19.720459
                                    c0.2866211-6.6090088,1.0693359-13.2009277,1.6567383-19.8166504c-2.4020996,13.0233154-4.6589355,26.2669678-3.7382813,39.730957
                                    c0.0644531,1.6800537,0.3100586,3.3498535,0.4631348,5.0273438c0.2773438,1.663208,0.467041,3.3427734,0.855957,4.9898682
                                    c0.621582,3.3249512,1.6428223,6.5695801,2.7663574,9.7695313c1.1696777,3.1743164,2.6477051,6.2680664,4.4082031,9.189209
                                    c1.7541504,2.9255371,3.8024902,5.6435547,5.7910156,8.2731934c2.0046387,2.6260986,3.9143066,5.2502441,5.588623,8.0344238
                                    l1.2211914,2.1072998l1.1464844,2.1529541c0.736084,1.4302979,1.3815918,2.9080811,2.0236816,4.4162598
                                    c2.5424805,6.0368652,4.6008301,12.3322754,6.6137695,18.6204834c1.958252,6.3121338,3.776123,12.6772461,5.4406738,19.0876465
                                    c0.0603638,0.22229,0.1148682,0.4460449,0.1745605,0.668457c0.392395-0.4610596,0.7868652-0.9202271,1.1727295-1.3869629
                                    C231.383667,209.2171478,229.8768311,202.8671722,228.1331177,196.5755463z"></path>
                                <path fill="#576C80" d="M95.1263428,52.7443047c1.2451172-5.9648438,2.3519897-11.9220581,3.4879761-17.868103
                                    l1.7856445-9.2026367l0.8925781-4.5982666l0.9333496-4.5897217l0.918457-4.5926504
                                    c0.0328369-0.1578369,0.0671387-0.3153076,0.1004028-0.4730225c-1.0633545,0.2701416-2.1218262,0.5523071-3.1742554,0.8490601
                                    c-0.2636719,1.2072754-0.5256348,2.414856-0.7835693,3.6234741c-0.5925293,3.0887442-1.168457,6.180419-1.6835938,9.2825918
                                    c-0.463623,3.111084-0.9538574,6.2167969-1.3024902,9.3430176c-0.7263794,6.0909424-1.2094727,12.2125854-1.2838135,18.355835
                                    c-0.0018921,0.1542358-0.010498,0.3081055-0.0118408,0.4624023C95.0466919,53.1388969,95.085144,52.9416924,95.1263428,52.7443047
                                    z"></path>
                            </g>
                        </g>
                        <g class="crosshair">
                            <path fill="#EE3E3E" d="M130.2265625,152.779953c-0.3271484,10.5160828-8.5358505,19.311142-19.311142,19.311142
                                    c-10.5229492,0-19.311142-8.7883759-19.311142-19.311142c0-10.2755585,8.3688812-18.9184723,18.62146-19.2896881
                                    C121.262558,133.0906525,129.8912659,142.0018921,130.2265625,152.779953c0.0998535,3.2098389,5.1002808,3.2238007,5,0
                                    c-0.4113617-13.223175-10.7686691-24.311142-24.311142-24.311142c-13.2330704,0-24.311142,11.078064-24.311142,24.311142
                                    s11.0780716,24.311142,24.311142,24.311142c13.5424728,0,23.8997803-11.0879669,24.311142-24.311142
                                    C135.3266907,149.5614929,130.3265839,149.5648041,130.2265625,152.779953z"></path>
                            <path fill="#EE3E3E" d="M108.4154205,122.1334305c0,6.4375076,0,12.8750229,0,19.3125381c0,3.2172546,5,3.22258,5,0
                                    c0-6.4375153,0-12.8750305,0-19.3125381C113.4154205,118.9161758,108.4154205,118.9108429,108.4154205,122.1334305
                                    L108.4154205,122.1334305z"></path>
                            <path fill="#EE3E3E" d="M108.4154205,167.3026428c0,6.4375153,0,12.8750305,0,19.3125458c0,3.2172546,5,3.22258,5,0
                                    c0-6.4375153,0-12.8750305,0-19.3125458C113.4154205,164.0853882,108.4154205,164.0800629,108.4154205,167.3026428
                                    L108.4154205,167.3026428z"></path>
                            <path fill="#EE3E3E" d="M78.6745453,156.8743134c6.4375153,0,12.8750229,0,19.3125305,0c3.2172623,0,3.2225952-5,0-5
                                    c-6.4375076,0-12.8750153,0-19.3125305,0C75.457283,151.8743134,75.4519577,156.8743134,78.6745453,156.8743134
                                    L78.6745453,156.8743134z"></path>
                            <path fill="#EE3E3E" d="M123.8437653,156.8743134c6.4375153,0,12.8750153,0,19.3125305,0c3.2172546,0,3.2225952-5,0-5
                                    c-6.4375153,0-12.8750153,0-19.3125305,0C120.626503,151.8743134,120.62117,156.8743134,123.8437653,156.8743134
                                    L123.8437653,156.8743134z"></path>
                            <circle fill="#EE3E3E" cx="110.9154205" cy="154.3743134" r="2.7598696"></circle>
                        </g>
                    </svg>
                    <h4 class="text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        膝頭肉
                    </h4>
                </div>
                <div class="col-sm-12 col-lg-3 d-flex flex-column align-items-center mb-4">
                    <svg class="icon thigh mb-2" version="1.2" baseProfile="tiny" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 269.2913513 269.2913513" xml:space="preserve">
                        <g>
                            <circle fill="#FFFFFF" stroke="#576C80" stroke-width="6" stroke-miterlimit="10" cx="134.6456757" cy="134.6456757" r="127.1374512"></circle>
                            <g>
                                <path fill="#576C80" d="M104.6303101,107.9631805c1.3052979,1.9996338,2.7776489,3.8742065,4.3109131,5.6764526
                                    c1.5585938,1.8256836,3.2004395,3.5656738,4.8989258,5.2371826c3.3796387,3.362793,6.9372559,6.4892578,10.3493652,9.7907715
                                    c-3.0161133-3.6715088-6.3088379-7.1118164-9.4125977-10.6694336c-1.5449219-1.7858887-3.0598145-3.5876465-4.4777832-5.453125
                                    c-1.4216309-1.8626709-2.7226563-3.8028564-3.920166-5.7912598l-0.0227051-0.0377197l-0.0549316-0.0706787
                                    c-3.9179688-5.0477295-7.6321411-10.2429199-11.1621094-15.5586548c-0.5813599,0.7772217-1.1601563,1.555542-1.739502,2.3336792
                                    C96.9231567,98.4357147,100.6571655,103.3024139,104.6303101,107.9631805z"></path>
                                <path fill="#576C80" d="M91.2681274,85.0999603c-1.1635742-1.8543701-2.2836914-3.7363281-3.4279785-5.6026611
                                    l-3.2905273-5.6860352l-3.1906738-5.7456055l-3.0588379-5.8201942l-2.9772949-5.8666992l-2.8713379-5.9240723
                                    l-2.8066406-5.9602051l-2.7258301-6.0026855c-1.3338013-2.9448242-2.6380615-5.9050903-3.9349365-8.8699341
                                    c-0.4098511,0.2802124-0.8173828,0.5634155-1.2237549,0.8482666c1.0647583,2.9463501,2.1523438,5.8850708,3.286377,8.8079224
                                    l2.480957,6.1430664c0.8652344,2.0323486,1.7033691,4.0777588,2.5922852,6.1011963l2.7419434,6.0394287
                                    c0.9577637,1.9934082,1.8859863,4.0020752,2.8691406,5.9842529l3.0397949,5.9029579l3.1762695,5.8341064
                                    c1.1062012,1.9190674,2.2194824,3.8342285,3.3588867,5.7335205c1.173584,1.8793945,2.322998,3.7735596,3.5163574,5.6401367
                                    c1.3858643,2.1480713,2.8226929,4.2629395,4.2872925,6.3584595c0.6374512-0.6994629,1.2724609-1.401062,1.9176025-2.0934448
                                    C93.7506714,88.9963226,92.4954834,87.0569305,91.2681274,85.0999603z"></path>
                                <path fill="#576C80" d="M50.3686523,156.8628998l4.2646484-6.9396973l4.3596191-6.880249l4.407959-6.8492432l4.5012207-6.7889404
                                    l4.5578613-6.7525635l4.6323242-6.7043457c5.3065796-7.5901489,10.7619019-15.0793457,16.3074341-22.5274658
                                    c0.5793457-0.7781372,1.1581421-1.5564575,1.739502-2.3336792c0.3842163-0.5136108,0.7645264-1.0289917,1.1495972-1.5422974
                                    c-0.4277954,0.4523315-0.8371582,0.921814-1.2615967,1.3773193c-0.6451416,0.6923828-1.2801514,1.3939819-1.9176025,2.0934448
                                    c-6.2919312,6.9030762-12.2406616,14.1217651-17.9013672,21.5581665c-1.6398926,2.1811523-3.2878418,4.3580322-4.8903809,6.567627
                                    l-4.7556152,6.6671143l-4.6049805,6.7722168l-4.4912109,6.8487549l-4.3266602,6.9537354l-4.2102051,7.0246582
                                    l-4.0490723,7.1184082c-1.3303223,2.3834229-2.6157227,4.7921143-3.9033203,7.1981201
                                    c-5.1010742,9.6505127-9.8046875,19.5175781-13.8408203,29.6529541
                                    c5.1672363-9.6053467,10.4257813-19.12146,15.8879395-28.5172119l4.1340332-7.0223389L50.3686523,156.8628998z"></path>
                                <path fill="#576C80" d="M145.8200684,97.6845551c-2.6884766,2.5830078-5.1933594,5.368042-7.4985352,8.3183594
                                    c-2.2919922,2.9598389-4.3984375,6.0635986-6.3791504,9.2474365c-3.8837891,6.4165039-7.1628418,13.1785889-10.1220703,20.0440674
                                    c-2.9838867,6.8621826-5.6689453,13.8085938-8.3779297,20.770874c-2.652832,6.9796143-5.1213379,14.0286865-7.5148926,21.1029053
                                    c-2.3237305,7.0977783-4.5131836,14.2393799-6.5668945,21.4197998
                                    c-1.9865723,7.1993408-3.8874512,14.4226074-5.5563965,21.7005615
                                    c-1.6811523,7.2749023-3.1582031,14.5941162-4.4956055,21.9345703c-0.5903931,3.5200195-1.168457,7.0408936-1.7088013,10.5665283
                                    c0.2602539,0.1037598,0.5206909,0.2073364,0.7817993,0.3093872c2.3539429-10.810791,4.9191895-21.5604858,7.6999512-32.2488403
                                    c3.7998047-14.3756104,8.0012207-28.6362305,12.6904297-42.7397461
                                    c4.6569824-14.1075439,9.791748-28.0817871,15.1940918-41.9249268c2.7526855-6.890625,5.7023926-13.6972656,9.236084-20.2099609
                                    c1.7651367-3.2546387,3.6608887-6.4432373,5.7541504-9.5080566
                                    C141.0834961,103.429184,143.361084,100.4894867,145.8200684,97.6845551z"></path>
                                <path fill="#576C80" d="M234.970459,174.6717377c2.5009766-2.7792969,4.7512207-5.8214111,6.6010742-9.0836182
                                    c3.720459-6.5356445,5.9438477-13.7252197,7.4853516-20.9291992c3.2456055-14.4672852,2.5397949-29.7073975-1.3552246-43.9276123
                                    c-1.9858398-7.1125488-4.8054199-13.9752197-8.282959-20.463501c-3.5637207-6.4381104-7.4821777-12.6478271-12.1066895-18.3873329
                                    c-2.3056641-2.8648682-4.782959-5.5950928-7.3850098-8.1761475c-2.5942383-2.5899658-5.276123-5.0653076-7.8933105-7.5843506
                                    c-5.2756348-4.9925537-10.1542969-10.3691406-14.5769043-16.1367188
                                    c-2.3536987-3.0864258-4.6020508-6.2555542-6.7716675-9.4807129c-0.4480591-0.2203979-0.895752-0.4411621-1.3468018-0.6564331
                                    c2.3187866,3.6555176,4.7312622,7.2541504,7.2915649,10.7589722c4.3256836,5.8942871,9.1547852,11.4456787,14.3615723,16.5825195
                                    c2.5966797,2.5726318,5.2426758,5.0726318,7.7724609,7.6657715c2.5393066,2.5839844,4.9316406,5.2910156,7.1594238,8.1320801
                                    c4.4428711,5.6798134,8.296875,11.8707314,11.6474609,18.2558632c3.2734375,6.4251709,5.8608398,13.1922607,7.671875,20.1514893
                                    c3.6484375,13.9104004,4.1052246,28.6394043,0.9545898,42.6499023c-1.5185547,7.019165-3.7294922,13.9013672-7.2233887,20.0678711
                                    c-1.734375,3.0864258-3.8322754,5.9453125-6.192627,8.5914307c-2.3569336,2.6530762-5.0234375,5.0524902-7.7519531,7.4382324
                                    c-2.7333984,2.385498-5.5544434,4.7481689-8.1855469,7.3670654c-2.6396484,2.6022949-5.1228027,5.4088135-7.276123,8.4539795
                                    c-2.1791992,3.0198975-4.010498,6.307251-5.6628418,9.5531006c-1.7451172,3.1845703-3.442627,6.4077148-5.1262207,9.651123
                                    c-6.6417236,12.8021851-13.0020752,25.725769-19.5216675,38.5618896c0.7279663-0.2728271,1.4554443-0.5466919,2.177002-0.8324585
                                    c6.4933472-12.2085571,12.8555298-24.4810181,19.4545288-36.6065063c1.7353516-3.192749,3.5119629-6.3760986,5.2880859-9.5722656
                                    c1.6904297-3.2271729,3.4567871-6.3000488,5.578125-9.1717529c2.0910645-2.8833008,4.487793-5.5280762,7.0654297-8.0100098
                                    c2.574707-2.4851074,5.3405762-4.8127441,8.1103516-7.2025146
                                    C229.6940918,179.9340668,232.4716797,177.4601898,234.970459,174.6717377z"></path>
                            </g>
                        </g>
                        <g class="crosshair">
                            <path fill="#EE3E3E" d="M121.4622421,158.8393097c-0.3271484,10.5160828-8.5358505,19.311142-19.311142,19.311142
                                    c-10.5229492,0-19.311142-8.7883759-19.311142-19.311142c0-10.2755585,8.3688812-18.9184723,18.6214523-19.2896881
                                    C112.4982376,139.1500244,121.1269455,148.0612488,121.4622421,158.8393097c0.0998535,3.2098541,5.1002884,3.2238007,5,0
                                    c-0.4113617-13.223175-10.7686691-24.311142-24.311142-24.311142c-13.2330704,0-24.311142,11.0780792-24.311142,24.311142
                                    c0,13.233078,11.0780716,24.311142,24.311142,24.311142c13.5424728,0,23.8997803-11.0879669,24.311142-24.311142
                                    C126.5623627,155.6208496,121.5622635,155.6241608,121.4622421,158.8393097z"></path>
                            <path fill="#EE3E3E" d="M99.6511002,128.1927948c0,6.4375153,0,12.8750153,0,19.3125305c0,3.2172546,5,3.2225952,5,0
                                    c0-6.4375153,0-12.8750153,0-19.3125305C104.6511002,124.9755325,99.6511002,124.9702072,99.6511002,128.1927948
                                    L99.6511002,128.1927948z"></path>
                            <path fill="#EE3E3E" d="M99.6511002,173.3620148c0,6.4375,0,12.8750153,0,19.3125305c0,3.2172546,5,3.22258,5,0
                                    c0-6.4375153,0-12.8750305,0-19.3125305C104.6511002,170.1447449,99.6511002,170.1394196,99.6511002,173.3620148
                                    L99.6511002,173.3620148z"></path>
                            <path fill="#EE3E3E" d="M69.9102249,162.93367c6.4375076,0,12.8750229,0,19.3125305,0c3.2172623,0,3.2225952-5,0-5
                                    c-6.4375076,0-12.8750229,0-19.3125305,0C66.6929626,157.93367,66.6876373,162.93367,69.9102249,162.93367L69.9102249,162.93367z"></path>
                            <path fill="#EE3E3E" d="M115.0794449,162.93367c6.4375076,0,12.8750153,0,19.3125229,0c3.2172699,0,3.2225952-5,0-5
                                    c-6.4375076,0-12.8750153,0-19.3125229,0C111.8621826,157.93367,111.8568497,162.93367,115.0794449,162.93367
                                    L115.0794449,162.93367z"></path>
                            <circle fill="#EE3E3E" cx="102.1511002" cy="160.43367" r="2.7598696"></circle>
                        </g>
                    </svg>
                    <h4 class="text-center"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        大腿內側
                    </h4>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    /*import {
        TimelineMax,
        TweenMax,
        SteppedEase
    } from "gsap";*/
    import Vivus from "vivus";

    export default {
        name: "MonoSculptingFeature2",
        data () {
            return {
                patternVivus: null,
                isPatternVivusDone: false,
            };
        },
        mounted () {
            this.patternVivus = new Vivus(
                "pattern-4",
                {
                    type: "delayed",
                    start: "inViewport",
                    duration: 150
                },
                () => {
                    this.isPatternVivusDone = true;
                }
            );

            /* const scene = this.$scrollmagic.scene({
                triggerElement: "#mono-sculpting-trigger",
                triggerHook: "onLeave",
                duration: "100%",
                reverse: true
            });*/

            //const tweenTimeline = new TimelineMax();

            //tweenTimeline.add(
            //    TweenMax.to(".icons", 1, {/* Placeholder */})
            //);

            /*tweenTimeline.add(
                TweenMax.to(".icons", 1, {
                    opacity: 1
                })
            );*/

            //tweenTimeline.add(
            //    TweenMax.to(".icon", 2, {/* Placeholder */})
            //);

            /*scene
                .setTween(
                    tweenTimeline
                )
                .setPin(".feature-2-wrapper")
                .addIndicators();

            this.$scrollmagic.addScene(scene);*/
        }
    }
</script>

<style lang="scss" scoped>
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins/_breakpoints";
    @import "./variables.scss";

    .feature-2 {
        background-color: $background-color-alt;
        color: $text-color-alt;

        /*#mono-sculpting-trigger {
            top: -60px;
        }*/

        .feature-2-wrapper {

        }

        .icons {
            //opacity: 0;

            .icon {
                max-width: 140px;
                width: 100%;

                transform-origin: top;

                .crosshair {
                    animation: fade 3000ms infinite;
                    animation-direction: alternate;
                }
            }
        }

        .pattern {
            top: 1rem;
            left: 0;
            bottom: 1rem;

            @include media-breakpoint-up(md) {
                left: unset;
                right: 0;
            }

            svg {
                &#pattern-4 {
                    path {
                        transition: fill 250ms;
                    }

                    &.done {
                        path {
                            fill: $background-color;
                        }
                    }
                }
            }
        }
    }

    @keyframes fade {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
</style>
